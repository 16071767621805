export default {
	ROOM: 'room',
	PRODUCT: 'product',
	toString: (val) => {
		switch (val) {
			case 'room':
				return '시설';
			case 'product':
				return '물품';
			default:
				return '-';
		}
	},
};
