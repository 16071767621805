import React from 'react';
import styled, { css } from 'styled-components';

const DashboardSummaryCard = ({ title, background, border, color, count, large, xlarge, onClick }) => {
	return (
		<CardContainer background={background} border={border} large={large} xlarge={xlarge} onClick={onClick}>
			<Title white={large || xlarge}>{title}</Title>
			<Count color={color} large={large} xlarge={xlarge}>
				{count}
			</Count>
		</CardContainer>
	);
};

const CardContainer = styled.div`
	width: 170px;
	height: 102px;
	border-radius: 10px;
	cursor: pointer;

	& + & {
		margin-left: 10px;
	}

	background: ${(props) => props.background};
	border: 2px solid ${(props) => props.border};

	${(props) =>
		props.large &&
		css`
			width: 255px;
		`}

	${(props) =>
		props.xlarge &&
		css`
			width: 303px;
		`}
`;

const Title = styled.div`
	margin-top: 16px;
	margin-left: 21px;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;
	color: #000000;

	${(props) =>
		props.white &&
		css`
			color: #ffffff;
		`}
`;

const Count = styled.div`
	width: 170px;
	margin-top: 8px;
	padding-right: 30px;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 37px;
	line-height: 44px;
	text-align: right;
	color: ${(props) => props.color};

	${(props) =>
		props.large &&
		css`
			width: 255px;
		`}

	${(props) =>
		props.xlarge &&
		css`
			width: 303px;
		`}
`;

export default DashboardSummaryCard;
