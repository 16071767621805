import React, { useEffect, useState, useContext } from 'react';
import styled, { css } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import 'swiper/css';

import { UserContext } from '../contexts/UserContext';
import useSizeDetector from '../hooks/useSizeDetector';
import ApprovalStatus from '../constants/ApprovalStatus';
import RentalStatus from '../constants/RentalStatus';
import BoardSlug from '../constants/BoardSlug';
import UnitType from '../constants/UnitType';

import BannerImg from '../custom/images/banner.png';
import MyStatusBackgroundImg from '../custom/images/myStatusBackground.png';
import MyStatusBackgroundMobileImg from '../custom/images/myStatusBackground_mobile.png';
import LoginIconImg from '../custom/images/login.svg';
import MypageIconImg from '../custom/images/mypage.svg';
import NoticeBackgroundImg from '../custom/images/noticeBackground.png';
import PlusIconImg from '../custom/images/plus.svg';
import UnivGnuImg from '../custom/images/univ_gnu.jpg';
import UnivGyeongnamGeochangImg from '../custom/images/univ_gyeongnam_geochang.jpg';
import UnivKayaImg from '../custom/images/univ_kaya.jpg';
import UnivGeojeImg from '../custom/images/univ_geoje.png';
import UnivDistImg from '../custom/images/univ_dist.jpg';
import UnivMasanImg from '../custom/images/univ_masan.jpg';
import UnivYsuImg from '../custom/images/univ_ysu.jpg';
import UnivUlsanImg from '../custom/images/univ_ulsan.jpg';
import UnivInjeImg from '../custom/images/univ_inje.jpg';
import UnivJinjuHealthImg from '../custom/images/univ_jinju_health.jpg';
import UnivChangshinImg from '../custom/images/univ_changshin.jpg';
import UnivChangwonMoonsungImg from '../custom/images/univ_changwon_moonsung.jpg';
import UnivChangwonImg from '../custom/images/univ_changwon.jpg';
import UnivChoonhaeImg from '../custom/images/univ_choonhae.jpg';
import UnivGyeongnamNamhaeImg from '../custom/images/univ_gyeongnam_namhae.jpg';
import UnivGimhaeImg from '../custom/images/univ_gimhae.jpg';
import UnivYonamImg from '../custom/images/univ_yonam.jpg';
import UnivUcImg from '../custom/images/univ_uc.jpg';
import UnivKlcImg from '../custom/images/univ_klc.jpg';
import UnivKyungnamImg from '../custom/images/univ_kyungnam.jpg';
import UnivBpuImg from '../custom/images/univ_bpu.jpg';
import UnivCueImg from '../custom/images/univ_cue.jpg';
import UnivIukImg from '../custom/images/univ_iuk.jpg';
import UnivPrevButtonImg from '../custom/images/univ_prev_button.png';
import UnivNextButtonImg from '../custom/images/univ_next_button.png';

import * as api from '../apis';

const Main = () => {
	const navigate = useNavigate();
	const { user } = useContext(UserContext);
	const { isDesktop, Desktop, Mobile } = useSizeDetector();

	const [noticeBoard, setNoticeBoard] = useState(null);
	const [postList, setPostList] = useState(null);
	const [bookingList, setBookingList] = useState(null);
	const [swiperInstance, setSwiperInstance] = useState(null);

	const univList = [
		{
			image: UnivGeojeImg,
			link: 'https://www.koje.ac.kr/www',
		},
		{
			image: UnivGyeongnamGeochangImg,
			link: 'https://www.gc.ac.kr/newgc/main/index.asp',
		},
		{
			image: UnivGyeongnamNamhaeImg,
			link: 'https://univ.namhae.ac.kr/intro/intro.html',
		},
		{
			image: UnivGimhaeImg,
			link: 'https://www.gimhae.ac.kr/CmsHome/MainDefault.aspx',
		},
		{
			image: UnivDistImg,
			link: 'https://www.dist.ac.kr/kor/Main.do',
		},
		{
			image: UnivMasanImg,
			link: 'https://www.masan.ac.kr/kor/Main.do?sso=S',
		},
		{
			image: UnivYonamImg,
			link: 'https://www.yc.ac.kr/yonam/web/main/mainPage.do',
		},
		{
			image: UnivUcImg,
			link: 'https://www.uc.ac.kr/www/?pCode=main',
		},
		{
			image: UnivJinjuHealthImg,
			link: 'https://www.jhc.ac.kr/',
		},
		{
			image: UnivChangwonMoonsungImg,
			link: 'https://www.cmu.ac.kr/main.do?go_main=Y',
		},
		{
			image: UnivChoonhaeImg,
			link: 'https://www.ch.ac.kr/main/index.php',
		},
		{
			image: UnivKlcImg,
			link: 'https://www.klc.ac.kr/klc/web/main/mainPage.do',
		},
		{
			image: UnivKayaImg,
			link: 'https://www.kaya.ac.kr/Home/Main.mbz',
		},
		{
			image: UnivKyungnamImg,
			link: 'https://www.kyungnam.ac.kr/sites/ko/index.do',
		},
		{
			image: UnivBpuImg,
			link: 'https://www.bpu.ac.kr/Default.aspx',
		},
		{
			image: UnivGnuImg,
			link: 'https://www.gnu.ac.kr/main/main.do',
		},
		{
			image: UnivYsuImg,
			link: 'https://www.ysu.ac.kr/kor/Main.do',
		},
		{
			image: UnivUlsanImg,
			link: 'https://www.ulsan.ac.kr/kor/Main.do',
		},
		{
			image: UnivInjeImg,
			link: 'https://www.inje.ac.kr/kor/main/main.asp',
		},
		{
			image: UnivCueImg,
			link: 'https://www.cue.ac.kr/kor/Main.do',
		},
		{
			image: UnivChangshinImg,
			link: 'https://www.cs.ac.kr/main',
		},
		{
			image: UnivChangwonImg,
			link: 'https://www.changwon.ac.kr/ipsi/main.do?intro=off',
		},
		{
			image: UnivIukImg,
			link: 'http://www.iuk.ac.kr/page/main/index.php',
		},
	];

	useEffect(() => {
		if (user) {
			let filterParams = [`page_size=2`];
			api.getMyBooking(filterParams).then((res) => {
				if (res && res.data) {
					setBookingList(res.data);
				}
			});
		}

		api.getBoards().then((res) => {
			if (res && res.data) {
				let noticeBoard = res.data.find((board) => board.slug === BoardSlug.NOTICE);
				setNoticeBoard(noticeBoard);
				api.getPosts(noticeBoard.id, [`page_size=6`]).then((res) => {
					if (res && res.data) {
						setPostList(res.data.items);
					}
				});
			}
		});
	}, [user]);

	const onClickLogin = () => {
		navigate('/login');
	};

	const onClickMypage = () => {
		navigate('/mypage');
	};

	const onClickNoticeBoard = () => {
		navigate('/boards/notice');
	};

	const onClickPostContents = (postId) => {
		navigate(`/boards/${noticeBoard.id}/posts/${postId}`);
	};

	const onClickBooking = (placeCategoryId) => {
		if (!user) {
			navigate('/login');
			return;
		}
		navigate(`/booking`, {
			state: {
				place_category_id: placeCategoryId,
			},
		});
	};

	const renderBoardContentsRow = (post, idx) => {
		return (
			<BoardContentsRow key={idx} onClick={() => onClickPostContents(post.id)}>
				<BoardContentsCol>
					<BoardCategory>
						<BoardCategoryText>{post.category?.name ?? '-'}</BoardCategoryText>
					</BoardCategory>
					<BoardContentTitle>{post.title}</BoardContentTitle>
				</BoardContentsCol>
				<BoardContentDate>
					{post.created_at ? new Date(post.created_at).toLocaleDateString() : '-'}
				</BoardContentDate>
			</BoardContentsRow>
		);
	};

	const renderMyBooking = () => {
		if (!bookingList || bookingList.items.length === 0) {
			return <NoBookingList>예약 내역이 없습니다.</NoBookingList>;
		}
		return (
			<MyBookingContainer>
				{bookingList?.items.map((booking, idx) => {
					const bookingUnitType = booking.unit.unit_type_name;
					const locationDetail =
						bookingUnitType === UnitType.ROOM
							? `${booking.unit.name} ${booking.room_item.name}`
							: `${booking.unit.name}`;
					return renderBookingDetailRow(
						idx,
						bookingUnitType,
						booking.approval_status,
						booking.rental_status,
						booking.booking_date.split('~')[0],
						locationDetail
					);
				})}
			</MyBookingContainer>
		);
	};

	const renderBookingDetailRow = (idx, type, approvalStatus, rentalStatus, calendarDetail, locationDetail) => {
		return (
			<MyContentsRow key={idx}>
				<BookingStatusBox status={approvalStatus}>
					<span>{ApprovalStatus.toString(approvalStatus)}</span>
				</BookingStatusBox>
				<BookingDetailContainer>
					<BookingDetailRow>
						<BookingType type={type}>{type === UnitType.ROOM ? '시설' : '장비'}</BookingType>
						{type === UnitType.PRODUCT && (
							<RentalStatusBox status={rentalStatus}>
								{RentalStatus.toString(rentalStatus)}
							</RentalStatusBox>
						)}
					</BookingDetailRow>
					<BookingDetailRow column>
						<BookingDetailText>{calendarDetail ?? '-'}</BookingDetailText>
						<BookingLocationDetailText>{locationDetail ?? '-'}</BookingLocationDetailText>
					</BookingDetailRow>
				</BookingDetailContainer>
			</MyContentsRow>
		);
	};

	return (
		<Container>
			<BannerBackground src={BannerImg}>
				<BannerContainer>
					<BannerTopContainer>
						<BoardContainer>
							<BoardLeftContainer>
								<BoardTitle>공지사항</BoardTitle>
								<BoardMore onClick={() => onClickNoticeBoard()}>
									<PlusButtonIcon src={PlusIconImg} />
								</BoardMore>
							</BoardLeftContainer>
							<BoardContentsContainer>
								{postList?.map((post, idx) => {
									return renderBoardContentsRow(post, idx);
								})}
							</BoardContentsContainer>
						</BoardContainer>
						<MyContainer src={isDesktop ? MyStatusBackgroundImg : MyStatusBackgroundMobileImg}>
							<MyTopContainer>
								{user ? (
									<>
										<MyStatusTitle>내 예약현황</MyStatusTitle>
										{renderMyBooking()}
									</>
								) : (
									<>
										<MyStatusTitle>내 예약현황</MyStatusTitle>
										<MyStatusLoginInfo>로그인 후 이용해 주세요.</MyStatusLoginInfo>
									</>
								)}
							</MyTopContainer>
							<MyBottomContainer>
								{user ? (
									<BottomButtonContainer onClick={onClickMypage}>
										<BottomText>마이페이지</BottomText>
										<BottomButtonIconContainer>
											<BottomButtonIcon src={MypageIconImg} />
										</BottomButtonIconContainer>
									</BottomButtonContainer>
								) : (
									<BottomButtonContainer onClick={onClickLogin}>
										<BottomText>로그인</BottomText>
										<BottomButtonIconContainer>
											<BottomButtonIcon src={LoginIconImg} />
										</BottomButtonIconContainer>
									</BottomButtonContainer>
								)}
							</MyBottomContainer>
						</MyContainer>
					</BannerTopContainer>
					<Desktop>
						<BannerBottomContainer>
							<UnivBookingListContainer>
								<UnivBookingTitle>시설예약 바로가기</UnivBookingTitle>
								<UnivBookingList>
									<UnivBooking background={UnivGnuImg} onClick={() => onClickBooking(11)} />
									<UnivBooking
										background={UnivGyeongnamGeochangImg}
										onClick={() => onClickBooking(31)}
									/>
									<UnivBooking background={UnivKayaImg} onClick={() => onClickBooking(32)} />
									<UnivBooking background={UnivGeojeImg} onClick={() => onClickBooking(33)} />
									<UnivBooking background={UnivDistImg} onClick={() => onClickBooking(34)} />
									<UnivBooking background={UnivMasanImg} onClick={() => onClickBooking(35)} />
									<UnivBooking background={UnivYsuImg} onClick={() => onClickBooking(36)} />
									<UnivBooking background={UnivUlsanImg} onClick={() => onClickBooking(37)} />
									<UnivBooking background={UnivInjeImg} onClick={() => onClickBooking(38)} />
									<UnivBooking background={UnivJinjuHealthImg} onClick={() => onClickBooking(39)} />
									<UnivBooking background={UnivChangshinImg} onClick={() => onClickBooking(40)} />
									<UnivBooking
										background={UnivChangwonMoonsungImg}
										onClick={() => onClickBooking(41)}
									/>
									<UnivBooking background={UnivChangwonImg} onClick={() => onClickBooking(42)} />
									<UnivBooking background={UnivChoonhaeImg} onClick={() => onClickBooking(43)} />
								</UnivBookingList>
							</UnivBookingListContainer>
						</BannerBottomContainer>
					</Desktop>
				</BannerContainer>
			</BannerBackground>
			<Mobile>
				<UnivBookingListContainer>
					<UnivBookingTitle>시설예약 바로가기</UnivBookingTitle>
					<UnivBookingList>
						<UnivBooking background={UnivGnuImg} onClick={() => onClickBooking(11)} />
						<UnivBooking background={UnivGyeongnamGeochangImg} onClick={() => onClickBooking(31)} />
						<UnivBooking background={UnivKayaImg} onClick={() => onClickBooking(32)} />
						<UnivBooking background={UnivGeojeImg} onClick={() => onClickBooking(33)} />
						<UnivBooking background={UnivDistImg} onClick={() => onClickBooking(34)} />
						<UnivBooking background={UnivMasanImg} onClick={() => onClickBooking(35)} />
						<UnivBooking background={UnivYsuImg} onClick={() => onClickBooking(36)} />
						<UnivBooking background={UnivUlsanImg} onClick={() => onClickBooking(37)} />
						<UnivBooking background={UnivInjeImg} onClick={() => onClickBooking(38)} />
						<UnivBooking background={UnivJinjuHealthImg} onClick={() => onClickBooking(39)} />
						<UnivBooking background={UnivChangshinImg} onClick={() => onClickBooking(40)} />
						<UnivBooking background={UnivChangwonMoonsungImg} onClick={() => onClickBooking(41)} />
						<UnivBooking background={UnivChangwonImg} onClick={() => onClickBooking(42)} />
						<UnivBooking background={UnivChoonhaeImg} onClick={() => onClickBooking(43)} />
					</UnivBookingList>
				</UnivBookingListContainer>
			</Mobile>
			<UnivListContainer>
				<UnivListContent>
					<UnivListButton background={UnivPrevButtonImg} onClick={() => swiperInstance.slidePrev()} />
					<UnivList>
						<Swiper
							spaceBetween={20}
							slidesPerView={isDesktop ? 5 : 1}
							loop={true}
							touchRatio={isDesktop ? 0 : 1}
							onSwiper={(swiper) => setSwiperInstance(swiper)}
						>
							{univList.map((univ, idx) => {
								return (
									<SwiperSlide key={idx}>
										<UnivLink background={univ.image} onClick={() => window.open(univ.link)} />
									</SwiperSlide>
								);
							})}
						</Swiper>
					</UnivList>
					<UnivListButton background={UnivNextButtonImg} onClick={() => swiperInstance.slideNext()} />
				</UnivListContent>
			</UnivListContainer>
		</Container>
	);
};

const Container = styled.div`
	width: 100%;
	height: 100%;
`;

const BannerBackground = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	position: relative;
	width: 100%;
	height: 1005px;
	top: 0px;

	${(props) =>
		props.src &&
		css`
			background-image: url(${props.src});
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
		`}

	@media only screen and (max-width: 767.98px) {
		width: 100%;

		height: calc(100vw * (964 / 320));
	}
`;

const BannerContainer = styled.div`
	width: 100%;
	height: 100%;
	max-width: 1240px;

	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	@media only screen and (max-width: 767.98px) {
		padding: 20px;
		justify-content: center;
	}
`;

const BannerTopContainer = styled.div`
	width: 100%;
	display: flex;

	@media only screen and (max-width: 767.98px) {
		flex-direction: column-reverse;
	}
`;

const BannerBottomContainer = styled.div`
	width: 100%;
	margin-top: 30px;
`;

const BoardContainer = styled.div`
	width: 925px;
	height: 382px;
	display: flex;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		height: 576px;
		margin-top: 20px;
		flex-direction: column;
	}
`;

const BoardLeftContainer = styled.div`
	width: 244px;
	height: 100%;
	display: flex;
	background-image: url(${NoticeBackgroundImg});
	background-size: cover;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		height: 36px;
		background-image: none;
		background-color: #002864;
	}
`;

const BoardTitle = styled.span`
	align-self: center;
	flex-grow: 1;
	padding-left: 32px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 32px;
	line-height: 38px;
	color: #ffffff;

	@media only screen and (max-width: 767.98px) {
		font-size: 20px;
		line-height: 24px;
		padding-left: 15px;
	}
`;

const BoardMore = styled.div`
	width: 65px;
	height: 65px;
	align-self: flex-end;

	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #00cad7;
	cursor: pointer;

	@media only screen and (max-width: 767.98px) {
		width: 36px;
		height: 36px;
		border: 1px solid #00cad7;
	}
`;

const BoardContentsContainer = styled.div`
	width: 681px;
	height: 100%;
	background-color: rgba(0, 133, 206, 0.85);
	display: flex;
	flex-direction: column;
	padding: 40px;
	box-sizing: border-box;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		height: auto;
		padding: 20px;
	}
`;

const PlusButtonIcon = styled.img`
	width: 24px;
	height: 24px;
	object-fit: contain;

	@media only screen and (max-width: 767.98px) {
		width: 24px;
		height: 24px;
		object-fit: contain;
	}
`;

const BoardContentsRow = styled.div`
	position: relative;
	width: 100%;
	height: 34px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-shrink: 0;
	box-sizing: border-box;
	cursor: pointer;

	& + & {
		margin-top: 20px;
	}

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		height: auto;
		padding: 0;
		border: none;
		align-items: flex-start;
		flex-direction: column;

		& + & {
			margin-left: 0;
			margin-top: 10px;
		}
	}
`;

const BoardContentsCol = styled.div`
	display: flex;
	height: 100%;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		flex-direction: column;
	}
`;

const BoardCategory = styled.div`
	width: 87px;
	height: 34px;
	flex-shrink: 0;

	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid #ffffff;

	@media only screen and (max-width: 767.98px) {
		width: 64.25px;
		height: 25.25px;
	}
`;

const BoardCategoryText = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 19.2px;
	color: #ffffff;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	@media only screen and (max-width: 767.98px) {
		font-size: 13px;
		line-height: 15.6px;
	}
`;

const BoardContentTitle = styled.span`
	margin-left: 20px;
	width: 400px;
	align-self: center;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 28px;
	color: #ffffff;
	overflow: hidden;
	cursor: pointer;
	white-space: nowrap;
	text-overflow: ellipsis;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		height: auto;
		margin-left: 0;
		margin-top: 3.75px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
`;

const BoardContentDate = styled.span`
	flex-shrink: 0;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16.8px;
	color: #ffffff;

	@media only screen and (max-width: 767.98px) {
		margin-top: 1px;
	}
`;

const MyContainer = styled.div`
	width: 295px;
	height: 382px;
	margin-top: -1px;
	margin-left: 20px;
	box-sizing: border-box;
	${(props) =>
		props.src &&
		css`
			background-image: url(${props.src});
			background-size: contain;
			background-repeat: no-repeat;
		`}

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		height: calc((100vw - 40px) * (318 / 280));
		flex-shrink: 0;
		margin: 0;
	}
`;

const MyTopContainer = styled.div`
	width: 100%;
	height: 317px;
	padding-left: 38px;
	display: flex;
	flex-direction: column;
	justify-content: center;

	@media only screen and (max-width: 767.98px) {
		height: calc(100% - ((100vw - 40px) * (49 / 280)));
		padding-left: 20px;
	}
`;

const NoBookingList = styled.div`
	margin-top: 20px;
	display: flex;
	width: 100%;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 19px;
	color: #333333;
`;

const MyBookingContainer = styled.div`
	margin-top: 40px;
	margin-bottom: 20px;

	@media only screen and (max-width: 767.98px) {
		margin-top: 25px;
	}
`;

const MyContentsRow = styled.div`
	height: 73px;
	display: flex;

	& + & {
		margin-top: 15px;
	}
`;

const BookingStatusBox = styled.div`
	width: 73px;
	height: 73px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 36px;
	border: 7px solid #56afe0;

	span {
		width: 30px;
		text-align: center;
		font-family: 'Pretendard';
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 19px;
		color: #0085ce;
	}
`;

const BookingDetailContainer = styled.div`
	margin-left: 20px;
	display: flex;
	flex-direction: column;
	flex: 1;
	justify-content: center;
`;

const BookingDetailRow = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;

	& + & {
		margin-top: 9px;
	}

	${(props) =>
		props.column &&
		css`
			flex-direction: column;
			align-items: flex-start;
		`}
`;

const BookingType = styled.div`
	width: 49px;
	height: 26px;
	border-radius: 50px;
	display: flex;
	align-items: center;
	justify-content: center;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;

	${(props) => {
		switch (props.type) {
			case UnitType.ROOM:
				return css`
					border: 1px solid #0085ce;
					color: #0085ce;
				`;
			case UnitType.PRODUCT:
				return css`
					border: 1px solid #fc9639;
					color: #fc9639;
				`;
			default:
				return css`
					border: 1px solid #0085ce;
					color: #0085ce;
				`;
		}
	}};
`;

const RentalStatusBox = styled.div`
	margin-left: 5px;
	width: 70px;
	height: 26px;
	border-radius: 50px;
	display: flex;
	align-items: center;
	justify-content: center;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	color: #ffffff;

	${(props) => {
		switch (props.status) {
			case RentalStatus.RENTAL_READY:
				return css`
					background-color: #00c6ba;
				`;
			case RentalStatus.RENTAL_DELAY:
				return css`
					background-color: #e7683f;
				`;
			case RentalStatus.RETURN_DELAY:
				return css`
					background-color: #ff3535;
				`;
			case RentalStatus.RENTAL_COMPLETE:
			case RentalStatus.RETURN_COMPLETE:
				return css`
					background-color: #0d93f4;
				`;
			default:
				return css`
					background-color: #00c6ba;
				`;
		}
	}};
`;

const BookingDetailText = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	color: #333333;
`;

const BookingLocationDetailText = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 19px;
	color: #333333;

	width: 150px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const MyBottomContainer = styled.div`
	width: 100%;
	height: 65px;
	padding-left: 38px;

	@media only screen and (max-width: 767.98px) {
		height: calc((100vw - 40px) * (51 / 280));
		padding-left: 0;
	}
`;

const MyStatusTitle = styled.span`
	margin-top: 50px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 32px;
	line-height: 38px;
	color: #002864;

	@media only screen and (max-width: 767.98px) {
		margin-top: 0;
		font-size: 24px;
		line-height: 29px;
	}
`;

const MyStatusLoginInfo = styled.span`
	margin-top: 15px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #000000;

	@media only screen and (max-width: 767.98px) {
		margin-top: 0;
		font-size: 14px;
		line-height: 17px;
	}
`;

const BottomButtonContainer = styled.div`
	cursor: pointer;
	width: 257px;
	height: 100%;
	padding-left: 29px;
	display: flex;
	align-items: center;

	@media only screen and (max-width: 767.98px) {
		width: calc((100vw - 40px) * (280 / 280));
		padding-left: 0;
	}
`;

const BottomText = styled.span`
	width: 163px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 29px;
	color: #ffffff;

	@media only screen and (max-width: 767.98px) {
		text-align: center;
		width: calc((100vw - 40px) * (231 / 280));
		font-size: 20px;
		line-height: 24px;
	}
`;

const BottomButtonIconContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	@media only screen and (max-width: 767.98px) {
		width: calc((100vw - 40px) * (49 / 280));
	}
`;

const BottomButtonIcon = styled.img`
	margin-left: 20px;

	@media only screen and (max-width: 767.98px) {
		margin-left: 0;
		width: 22px;
		height: 22px;
		object-fit: contain;
	}
`;

const UnivBookingListContainer = styled.div`
	width: 1240px;
	height: 420px;
	background-color: #ffffff;
	padding: 30px 20px;

	display: flex;
	flex-direction: column;
	align-items: center;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		height: 578px;
		padding: 20px 10px;
	}
`;

const UnivBookingTitle = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 34px;
	line-height: 41px;
	color: #27479d;

	@media only screen and (max-width: 767.98px) {
		margin-left: 5px;
		font-size: 22px;
		line-height: 26.4px;
		align-self: flex-start;
	}
`;

const UnivBookingList = styled.div`
	margin-top: 20px;
	display: flex;
	flex-wrap: wrap;

	@media only screen and (max-width: 767.98px) {
		margin-top: 15px;
		justify-content: center;
	}
`;

const UnivBooking = styled.div`
	width: 220px;
	height: 80px;
	margin: 10px;
	padding: 14px;
	cursor: pointer;
	transition: margin 0.3s;

	border: 1px solid #dddddd;
	${(props) =>
		props.background &&
		css`
			background-image: url(${props.background});
			background-origin: content-box;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
		`}

	@media only screen and (min-width: 768px) {
		&:hover {
			margin-top: 3px;
			border: 5px solid #0085ce;
			padding: 10px;
			box-shadow: 0px 14px 11px 2px rgba(69, 73, 92, 0.2);
		}
	}

	@media only screen and (max-width: 767.98px) {
		width: 135px;
		height: 60px;
		margin: 5px;
		padding: 7px;
	}
`;

const UnivListContainer = styled.div`
	width: 100%;
	height: 96px;
	background-color: #f1f3f6;

	display: flex;
	justify-content: center;
	align-items: center;
`;

const UnivListContent = styled.div`
	width: 1240px;
	display: flex;
	align-items: center;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		padding: 0 10px;
		justify-content: center;
	}
`;

const UnivListButton = styled.div`
	margin-top: 10px;
	width: 40px;
	height: 40px;
	background-size: contain;
	background-image: url(${(props) => props.background});
	cursor: pointer;
	flex-shrink: 0;

	@media only screen and (max-width: 767.98px) {
		margin-top: 5px;
		width: 27px;
		height: 27px;
	}
`;

const UnivList = styled.div`
	width: 1160px;
	padding: 0 15px;
	display: flex;

	@media only screen and (max-width: 767.98px) {
		width: 240px;
	}
`;

const UnivLink = styled.div`
	flex-shrink: 0;
	width: 210px;
	height: 60px;
	padding: 10px 25px;
	background-color: #ffffff;
	cursor: pointer;

	${(props) =>
		props.background &&
		css`
			background-image: url(${props.background});
			background-origin: content-box;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
		`}
`;

export default Main;
