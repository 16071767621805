import UnitType from '../constants/UnitType';

export default [
	{
		name: '시설 예약현황 관리',
		link: '/admin/booking/rooms',
		tab_unit: UnitType.ROOM,
	},
	{
		name: '장비 예약현황 관리',
		link: '/admin/booking/products',
		tab_unit: UnitType.PRODUCT,
		use_product: true,
	},
];
