import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const TimeSelect = ({ time, minHour, maxHour, onChange }) => {
	const [hour, setHour] = useState(null);
	const [minutes, setMinutes] = useState(null);

	useEffect(() => {
		if (time !== null) {
			let splited = time.split(':');
			setHour(parseInt(splited[0]));
			setMinutes(parseInt(splited[1]));
		} else {
			setHour(null);
			setMinutes(null);
		}
	}, [time]);

	const getAvailableHours = () => {
		if (minHour && maxHour) {
			return Array.from({ length: maxHour - minHour + 1 }, (_, i) => i + minHour);
		}
		return [...Array(24).keys()];
	};

	const onTimeChange = (hour, minutes) => {
		if (hour === null || minutes === null) {
			onChange(null);
			return;
		}

		let result = onChange(`${hour.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`);
		if (result === false) {
			setHour(null);
			setMinutes(null);
		}
	};

	return (
		<Container>
			<TimeContainer>
				<HourSelect
					value={hour ?? ''}
					onChange={(e) => {
						setHour(e.currentTarget.value);
						let changeMinutes = minutes;
						if (changeMinutes === null) {
							changeMinutes = 0;
							setMinutes(changeMinutes);
						}
						onTimeChange(e.currentTarget.value, changeMinutes);
					}}
				>
					<option value="">-</option>
					{getAvailableHours().map((selectHour, _) => (
						<option key={_} value={selectHour}>
							{selectHour.toString().padStart(2, '0')}
						</option>
					))}
				</HourSelect>
				<TimeLabel>시</TimeLabel>
			</TimeContainer>
			<TimeContainer>
				<MinutesSelect
					value={minutes ?? ''}
					onChange={(e) => {
						setMinutes(e.currentTarget.value);
						onTimeChange(hour, e.currentTarget.value);
					}}
				>
					<option value="">-</option>
					{[...Array(2).keys()].map((selectMinutes, _) => (
						<option key={_} value={selectMinutes * 30}>
							{(selectMinutes * 30).toString().padStart(2, '0')}
						</option>
					))}
				</MinutesSelect>
				<TimeLabel>분</TimeLabel>
			</TimeContainer>
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	margin-top: 5px;
`;

const TimeContainer = styled.div`
	& + & {
		margin-left: 15px;
	}
`;

const StyledSelect = styled.select`
	height: 35px;
	padding-left: 8px;

	border-radius: 4px;
	border: 1px solid #dddddd;

	appearance: none;
	background-image: linear-gradient(45deg, transparent 50%, gray 50%),
		linear-gradient(135deg, gray 50%, transparent 50%);
	background-position: calc(100% - 15px) calc(1em - 2px), calc(100% - 10px) calc(1em - 2px), calc(100% - 2.5em) 0.5em;
	background-size: 5px 5px, 5px 5px, 1px 1.5em;
	background-repeat: no-repeat;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;
`;

const HourSelect = styled(StyledSelect)`
	width: 60px;
`;

const MinutesSelect = styled(StyledSelect)`
	width: 60px;
`;

const TimeLabel = styled.span`
	margin-left: 8px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;
`;

export default TimeSelect;
