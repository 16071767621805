import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import { UserContext } from '../../contexts/UserContext';
import UserRole from '../../constants/UserRole';
import LogoImg from '../../custom/images/logo.png';
import CustomResource from '../../custom/resource';
import * as api from '../../apis';

const AdminHeader = ({ userRole, unitAdmins }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const { logout } = useContext(UserContext);
	const systemAdminMenus = [
		{
			path: '/admin/dashboard',
			link: '/admin/dashboard/room',
			title: '대시보드',
			default: true,
		},
		{
			path: '/admin/booking',
			link: '/admin/booking/rooms',
			title: '예약현황 관리',
		},
		{
			path: '/admin/unit',
			link: '/admin/unit/rooms',
			title: '예약항목 관리',
		},

		{
			path: '/admin/users',
			link: '/admin/users',
			title: '사용자 관리',
		},
		{
			path: '/admin/settings',
			link: '/admin/settings',
			title: '시스템 설정',
		},
	];
	const roomAdminMenus = [
		{
			path: '/admin/dashboard',
			link: '/admin/dashboard/room',
			title: '대시보드',
			default: true,
		},
		{
			path: '/admin/booking',
			link: '/admin/booking/rooms',
			title: '예약현황 관리',
		},
		{
			path: '/admin/unit',
			link: '/admin/unit/rooms',
			title: '예약항목 관리',
		},
		{
			path: '/admin/settings',
			link: '/admin/settings/unit/room/categories',
			title: '시스템 설정',
		},
	];
	const productAdminMenus = [
		{
			path: '/admin/dashboard',
			link: '/admin/dashboard/product',
			title: '대시보드',
			default: true,
		},
		{
			path: '/admin/booking',
			link: '/admin/booking/products',
			title: '예약현황 관리',
		},
		{
			path: '/admin/unit',
			link: '/admin/unit/products',
			title: '예약항목 관리',
		},
		{
			path: '/admin/settings',
			link: '/admin/settings/unit/product/categories',
			title: '시스템 설정',
		},
	];
	const roomUnitAdminMenus = [
		{
			path: '/admin/dashboard',
			link: '/admin/dashboard/room',
			title: '대시보드',
			default: true,
		},
		{
			path: '/admin/booking',
			link: '/admin/booking/rooms',
			title: '예약현황 관리',
		},
		{
			path: '/admin/unit',
			link: '/admin/unit/rooms',
			title: '예약항목 관리',
		},
	];
	const productUnitAdminMenus = [
		{
			path: '/admin/dashboard',
			link: '/admin/dashboard/product',
			title: '대시보드',
			default: true,
		},
		{
			path: '/admin/booking',
			link: '/admin/booking/products',
			title: '예약현황 관리',
		},
		{
			path: '/admin/unit',
			link: '/admin/unit/products',
			title: '예약항목 관리',
		},
	];

	const getMenus = (userRole, unitAdmins) => {
		// 관리자 권한별 메뉴
		switch (userRole) {
			case UserRole.SYSTEM_ADMINISTRATOR:
				return systemAdminMenus;
			case UserRole.ROOM_ADMINISTRATOR:
				return roomAdminMenus;
			case UserRole.PRODUCT_ADMINISTRATOR:
				return productAdminMenus;
		}

		// Unit 관리자 권한별 메뉴
		if (UserRole.isRoomUnitAdmin(userRole, unitAdmins)) {
			return roomUnitAdminMenus;
		} else if (UserRole.isProductUnitAdmin(userRole, unitAdmins)) {
			return productUnitAdminMenus;
		}

		return null;
	};

	const onClickLogo = () => {
		navigate('/');
	};

	const onClickLogout = async () => {
		try {
			const res = await api.logout();
			if (res && res.status === 204) {
				logout();
				navigate('/');
			}
		} catch (err) {
			console.error(err);
		}
	};

	const menus = getMenus(userRole, unitAdmins);

	return (
		<Container>
			<TopLine />
			<Content>
				<LogoContainer>
					<LogoImage onClick={onClickLogo} src={LogoImg} />
					<LogoText onClick={onClickLogo}>{CustomResource.site_title}</LogoText>
				</LogoContainer>
				<MenuContainer>
					{menus?.map((menu, idx) => {
						return (
							<MenuItemContainer key={idx}>
								<MenuItem
									selected={
										(location.pathname === '/admin' && menu.default) ||
										location.pathname.includes(menu.path)
									}
									onClick={() => navigate(menu.link)}
								>
									{menu.title}
								</MenuItem>
							</MenuItemContainer>
						);
					})}
					<LogoutButton onClick={onClickLogout}>로그아웃</LogoutButton>
				</MenuContainer>
			</Content>
		</Container>
	);
};

const Container = styled.div`
	position: fixed;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100vw;
	height: 90px;
	z-index: 100;
	background-color: white;
	box-sizing: border-box;
	border-bottom: 1px solid #dddddd;
`;

const TopLine = styled.div`
	width: 100%;
	height: 5px;
	background-color: #22499d;
`;

const Content = styled.div`
	display: flex;
	position: relative;
	width: 100%;
	max-width: 1240px;
	height: 85px;
	align-items: center;
	justify-content: space-between;
`;

const LogoContainer = styled.div`
	display: flex;
	height: 100%;
	flex-shrink: 0;
	align-items: center;
`;

const LogoImage = styled.img`
	width: 159px;
	height: 35px;
	cursor: pointer;
`;

const LogoText = styled.span`
	color: #000000;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 22px;
	line-height: 26px;
	margin-left: 20px;
	align-self: center;
	cursor: pointer;
`;

const MenuContainer = styled.div`
	display: flex;
	height: 100%;
	justify-content: center;
	align-items: center;
`;

const MenuItemContainer = styled.div`
	display: flex;

	@media only screen and (min-width: 768px) {
		height: 100%;
		& + & {
			margin-left: 51px;
		}
	}

	@media only screen and (max-width: 767.98px) {
		width: 100%;
	}
`;

const MenuItem = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
	border-bottom: 2px solid #ffffff;
	cursor: pointer;

	${(props) =>
		props.selected &&
		css`
			color: #0e498f;
			border-bottom: 3px solid #22499d;
			margin-bottom: -1px;
		`}
`;

const LogoutButton = styled.div`
	margin-left: 27px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 7px 13px;
	box-sizing: border-box;
	height: 35px;
	cursor: pointer;

	border-radius: 17px;
	border: 1px solid #275da6;
	background-color: #ffffff;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #000000;
`;

export default AdminHeader;
