const CustomResource = {
	univ_name: '울산·경남권역 대학원격교육지원센터',
	site_title: '스튜디오 예약시스템',
	sso_login: '울산·경남권역 대학원격교육지원센터 LMS ID/PW로 로그인합니다.',
	address1: '주소 : 52828 경상남도 진주시 진주대로 501 경상국립대학교',
	address2: '울산·경남권역 대학원격교육지원센터',
	contact: '',
	copyright: 'Copyright 2021 © rec All rights reserved.',
};

export default CustomResource;
