import React from 'react';
import styled from 'styled-components';
import { Circle } from 'better-react-spinkit';

const LoadingIndicator = ({ size, loading }) => {
	if (!loading) {
		return null;
	}

	return (
		<Container>
			<Indicator>
				<Circle size={size} color="black" />
			</Indicator>
		</Container>
	);
};

const Container = styled.div`
	position: fixed;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 10002;
	background-color: rgba(0, 0, 0, 0.05);
`;

const Indicator = styled.div`
	position: absolute;
	top: calc(50% - 25px);
	left: calc(50% - 25px);
	width: 50px;
	height: 50px;
`;

export default LoadingIndicator;
