import UnitType from '../constants/UnitType';

export default [
	{
		name: '시설 관리',
		link: '/admin/unit/rooms',
		tab_unit: UnitType.ROOM,
	},
	{
		name: '장비 관리',
		link: '/admin/unit/products',
		tab_unit: UnitType.PRODUCT,
		use_product: true,
	},
];
