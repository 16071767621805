import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { UserContext } from '../contexts/UserContext';
import useSizeDetector from '../hooks/useSizeDetector';
import UnitTypeTab from '../components/UnitTypeTab';
import TablePagination from '../components/TablePagination';
import BookingItemModal from '../components/modal/BookingItemModal';
import OperatorRequestModal from '../components/modal/OperatorRequestModal';
import LoadingIndicator from '../components/LoadingIndicator';
import ApprovalStatus from '../constants/ApprovalStatus';
import RentalStatus from '../constants/RentalStatus';
import CategorySlug from '../constants/CategorySlug';
import UnitType from '../constants/UnitType';

import RequestIconImage from '../assets/images/screens/MyPage/request_icon.svg';
import BreadcrumbHomeImg from '../assets/images/common/breadcrumb_home.svg';
import BreadcrumbSeparatorImg from '../assets/images/common/breadcrumb_separator.svg';
import LeftTopMenuBackgroundImg from '../assets/images/common/lefttop_menu_background.png';
import LeftTopMenuSelectedImg from '../assets/images/common/lefttop_menu_right_arrow.svg';

import * as api from '../apis';
import * as utils from '../utils';

const MyPage = () => {
	const navigate = useNavigate();
	const { user } = useContext(UserContext);
	const { Desktop, Mobile } = useSizeDetector();

	const [unitType, setUnitType] = useState(UnitType.ROOM);
	const [pageIndex, setPageIndex] = useState(1);
	const [filterStatus, setFilterStatus] = useState(null);
	const [bookingList, setBookingList] = useState(null);
	const [bookingItemModalVisiblity, setBookingItemModalVisiblity] = useState(false);
	const [bookingItemModalData, setBookingItemModalData] = useState(null);
	const [operatorRequestModalVisibility, setOperatorRequestModalVisibility] = useState(false);
	const [operatorRequestModalData, setOperatorRequestModalData] = useState(null);

	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (!user) {
			navigate('/login');
			return;
		}
	}, []);

	useEffect(() => {
		if (!user) {
			navigate('/login');
			return;
		}

		if (pageIndex !== 1) {
			setPageIndex(1);
		}
	}, [unitType]);

	useEffect(() => {
		if (!user) {
			navigate('/login');
			return;
		}

		getMyBookingFromApi();
	}, [unitType, pageIndex, filterStatus]);

	const getMyBookingFromApi = () => {
		let filterParams = [`unit_type=${unitType}`, `page=${pageIndex}`, `page_size=10`];
		if (filterStatus) filterParams.push(`approval_statuses[]=${filterStatus}`);
		api.getMyBooking(filterParams).then((res) => {
			if (res && res.data) {
				setBookingList(res.data);
			}
		});
	};

	const onClickHome = () => {
		navigate('/');
	};

	const onClickAdminRequest = (bookingItem) => {
		setOperatorRequestModalData(bookingItem);
		setOperatorRequestModalVisibility(true);
	};

	const onClickShowRequest = (bookingItem) => {
		setBookingItemModalVisiblity(true);
		setBookingItemModalData(bookingItem);
	};

	const onClickCancelBooking = (bookingItem) => {
		if (!window.confirm('선택된 예약을 취소하시겠습니까?')) return;
		setIsLoading(true);
		api.cancelBooking(bookingItem.id)
			.then((res) => {
				setIsLoading(false);
				if (res && res.data) {
					alert('취소되었습니다.');
					getMyBookingFromApi();
				}
			})
			.catch((err) => {
				setIsLoading(false);
				console.error(err);
			});
	};

	const onPageChange = (page) => {
		setPageIndex(page);
	};

	const onStatusChange = (status) => {
		setFilterStatus(status);
	};

	const renderTableHeader = () => {
		switch (unitType) {
			case UnitType.ROOM:
				return renderRoomTableHeader();
			case UnitType.PRODUCT:
				return renderProductTableHeader();
		}

		return null;
	};

	const renderRoomTableHeader = () => {
		return (
			<tr>
				<th style={{ minWidth: '70px' }}>예약번호</th>
				<th style={{ minWidth: '120px' }}>위치</th>
				<th style={{ minWidth: '130px' }}>시설명 / 호실</th>
				<th style={{ minWidth: '100px' }}>이용기간</th>
				<th style={{ minWidth: '100px' }}>신청일</th>
				<th style={{ minWidth: '80px' }}>예약상태</th>
				<th style={{ minWidth: '70px' }}>운영자요청</th>
				<th style={{ minWidth: '70px' }}>신청내역</th>
				<th style={{ minWidth: '70px' }}>예약취소</th>
			</tr>
		);
	};

	const renderProductTableHeader = () => {
		return (
			<tr>
				<th style={{ minWidth: '70px' }}>예약번호</th>
				<th style={{ minWidth: '120px' }}>위치</th>
				<th style={{ minWidth: '130px' }}>장비명/장비번호</th>
				<th style={{ minWidth: '60px' }}>이용기간</th>
				<th style={{ minWidth: '100px' }}>신청일</th>
				<th style={{ minWidth: '80px' }}>예약상태</th>
				<th style={{ minWidth: '70px' }}>운영자요청</th>
				<th style={{ minWidth: '80px' }}>수령/반납상태</th>
				<th style={{ minWidth: '70px' }}>신청내역</th>
				<th style={{ minWidth: '70px' }}>예약취소</th>
			</tr>
		);
	};

	const categorySlugPlace = unitType === UnitType.ROOM ? CategorySlug.PLACE : CategorySlug.PRODUCT_PLACE;

	return (
		<Content>
			<LoadingIndicator size={50} loading={isLoading} />
			<TitleContainer>
				<Title>마이페이지</Title>
				<Desktop>
					<Breadcrumb>
						<HomeIcon src={BreadcrumbHomeImg} onClick={onClickHome} />
						<BreadcrumbSeparator src={BreadcrumbSeparatorImg} />
						<BreadcrumbText current>마이페이지</BreadcrumbText>
					</Breadcrumb>
				</Desktop>
			</TitleContainer>
			<Row>
				<Desktop>
					<MyPageColumn>
						<MyPageMenuTop>
							<MyPageMenuTopTitle>마이페이지</MyPageMenuTopTitle>
						</MyPageMenuTop>
						<MenuContainer>
							<Menu>
								<MainMenu
									selected={unitType === UnitType.ROOM}
									onClick={() => setUnitType(UnitType.ROOM)}
								>
									<span>시설</span>
									<LeftTopMenuSelectedIcon />
								</MainMenu>
								{/* 장비를 사용하는 경우에만 마이페이지 장비 메뉴 표시 */}
								{JSON.parse(process.env.REACT_APP_USE_TYPE_PRODUCT) && (
									<MainMenu
										selected={unitType === UnitType.PRODUCT}
										onClick={() => setUnitType(UnitType.PRODUCT)}
									>
										<span>장비</span>
										<LeftTopMenuSelectedIcon />
									</MainMenu>
								)}
							</Menu>
						</MenuContainer>
					</MyPageColumn>
				</Desktop>
				<Mobile>
					<MyPageInfo>
						<MyPageTitle>나의 예약</MyPageTitle>
						<UnitTypeName>{unitType === UnitType.ROOM ? '시설' : '장비'}</UnitTypeName>
					</MyPageInfo>
				</Mobile>
				{JSON.parse(process.env.REACT_APP_USE_TYPE_PRODUCT) && (
					<Mobile>
						<UnitTypeTab
							tabs={[
								{ key: UnitType.ROOM, text: '시설' },
								{ key: UnitType.PRODUCT, text: '장비' },
							]}
							value={unitType}
							onChange={(changedUnitType) => setUnitType(changedUnitType)}
						/>
					</Mobile>
				)}
				<BookingListColumn>
					<Desktop>
						<MyPageInfo>
							<MyPageTitle>나의 예약</MyPageTitle>
							<UnitTypeName>{unitType === UnitType.ROOM ? '시설' : '장비'}</UnitTypeName>
						</MyPageInfo>
					</Desktop>
					<Mobile>
						<MobileDesription>표는 가로로 슬라이드 됩니다.</MobileDesription>
					</Mobile>
					<Desktop>
						<StatusButtonContainer>
							<StatusButton onClick={() => onStatusChange(null)} selected={filterStatus === null}>
								전체
							</StatusButton>
							<StatusButton
								onClick={() => onStatusChange(ApprovalStatus.APPROVED)}
								selected={filterStatus === ApprovalStatus.APPROVED}
							>
								{ApprovalStatus.toString(ApprovalStatus.APPROVED)}
							</StatusButton>
							<StatusButton
								onClick={() => onStatusChange(ApprovalStatus.PENDING)}
								selected={filterStatus === ApprovalStatus.PENDING}
							>
								{ApprovalStatus.toString(ApprovalStatus.PENDING)}
							</StatusButton>
							<StatusButton
								onClick={() => onStatusChange(ApprovalStatus.REJECTED)}
								selected={filterStatus === ApprovalStatus.REJECTED}
							>
								{ApprovalStatus.toString(ApprovalStatus.REJECTED)}
							</StatusButton>
							<StatusButton
								onClick={() => onStatusChange(ApprovalStatus.CANCELED)}
								selected={filterStatus === ApprovalStatus.CANCELED}
							>
								{ApprovalStatus.toString(ApprovalStatus.CANCELED)}
							</StatusButton>
						</StatusButtonContainer>
					</Desktop>
					<Mobile>
						<StatusButtonContainer>
							<StatusSelect
								value={filterStatus ? filterStatus : -1}
								onChange={(e) => {
									if (e.currentTarget.value == -1) {
										onStatusChange(null);
									} else {
										onStatusChange(e.currentTarget.value);
									}
								}}
							>
								<option value={-1}>{`전체`}</option>
								<option value={ApprovalStatus.APPROVED}>
									{ApprovalStatus.toString(ApprovalStatus.APPROVED)}
								</option>
								<option value={ApprovalStatus.PENDING}>
									{ApprovalStatus.toString(ApprovalStatus.PENDING)}
								</option>
								<option value={ApprovalStatus.REJECTED}>
									{ApprovalStatus.toString(ApprovalStatus.REJECTED)}
								</option>
								<option value={ApprovalStatus.CANCELED}>
									{ApprovalStatus.toString(ApprovalStatus.CANCELED)}
								</option>
							</StatusSelect>
						</StatusButtonContainer>
					</Mobile>
					<TableContainer>
						<BookingListTable>
							<thead>{renderTableHeader()}</thead>
							<tbody>
								{bookingList?.items.map((item, idx) => {
									return (
										<tr key={idx}>
											<td>{`#${item.id}`}</td>
											<td>
												{`${utils.getUnitCategoryName(item.unit, categorySlugPlace, 1)} 
											${utils.getUnitCategoryName(item.unit, categorySlugPlace, 2)}`}
												<br />
												{utils.getUnitCategoryName(item.unit, categorySlugPlace, 3)}
											</td>
											{/* [ROOM] 시설명/호실 */}
											{unitType === UnitType.ROOM && (
												<td className="strong" style={{ width: '130px' }}>{`${
													item.unit?.name ?? '-'
												} / ${item.room_item?.name ?? '-'}`}</td>
											)}
											{/* [PRODUCT] 장비명/장비번호 */}
											{unitType === UnitType.PRODUCT && (
												<td className="strong" style={{ width: '130px' }}>{`${
													item.unit?.name ?? '-'
												} / ${
													item.product_items
														?.map((productItem) => productItem.name)
														.join(',') ?? '-'
												}`}</td>
											)}
											<td className="strong">
												{item.booking_date
													? item.booking_date
															.split(' ')
															.map((line, _) => <div key={_}>{line}</div>)
													: '-'}
											</td>
											<td>
												{item.created_at ? new Date(item.created_at).toLocaleDateString() : '-'}
											</td>
											<td>
												<TableDataContainer>
													<ApprovalStatusText status={item.approval_status}>
														{ApprovalStatus.toString(item.approval_status) ?? '-'}
													</ApprovalStatusText>
												</TableDataContainer>
											</td>
											<td>
												{item.operator_request && (
													<OperatorRequestIcon
														src={RequestIconImage}
														onClick={() => onClickAdminRequest(item)}
													/>
												)}
											</td>
											{unitType === UnitType.PRODUCT && (
												<td>
													<TableDataContainer>
														<RentalStatusText status={item.rental_status}>
															{RentalStatus.toString(item.rental_status) ?? '-'}
														</RentalStatusText>
													</TableDataContainer>
												</td>
											)}
											<td>
												<TableDataContainer>
													<ShowBookingButton onClick={() => onClickShowRequest(item)}>
														보기
													</ShowBookingButton>
												</TableDataContainer>
											</td>
											<td>
												{item.approval_status !== ApprovalStatus.CANCELED && (
													<TableDataContainer>
														<CancelBookingButton onClick={() => onClickCancelBooking(item)}>
															취소
														</CancelBookingButton>
													</TableDataContainer>
												)}
											</td>
										</tr>
									);
								})}
							</tbody>
						</BookingListTable>
					</TableContainer>
					<div className="mt-24px">
						<TablePagination
							currentPage={bookingList?.pagination.current_page}
							lastPage={bookingList?.pagination.last_page}
							onPageChange={onPageChange}
						/>
					</div>
				</BookingListColumn>
			</Row>
			{bookingItemModalVisiblity && (
				<BookingItemModal
					unitType={unitType}
					booking={bookingItemModalData}
					onClose={() => setBookingItemModalVisiblity(false)}
				/>
			)}
			{operatorRequestModalVisibility && (
				<OperatorRequestModal
					unitType={unitType}
					data={operatorRequestModalData}
					onClose={(refresh) => {
						setOperatorRequestModalVisibility(false);
						if (refresh) {
							getMyBookingFromApi();
						}
					}}
				/>
			)}
		</Content>
	);
};

const Content = styled.div`
	width: 100%;
	max-width: 1240px;
	margin-top: 50px;
	margin-bottom: 115px;
	padding: 40px 30px;
	box-sizing: border-box;
	background-color: #ffffff;

	@media only screen and (max-width: 767.98px) {
		width: calc(100% - 20px);
		margin: 10px;
		padding: 15px 0 40px 0;
	}
`;

const TitleContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media only screen and (max-width: 767.98px) {
		padding: 0 10px;
	}
`;

const Title = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 36px;
	line-height: 43px;
	color: #000000;

	@media only screen and (max-width: 767.98px) {
		font-size: 24px;
		line-height: 29px;
	}
`;

const Breadcrumb = styled.div`
	display: flex;
	justify-content: flex-end;
`;

const HomeIcon = styled.img`
	cursor: pointer;
`;

const BreadcrumbSeparator = styled.img`
	margin: 0 10px;
`;

const BreadcrumbText = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;

	${(props) =>
		props.current &&
		css`
			color: #22499d;
		`}
`;

const Row = styled.div`
	display: flex;
	margin-top: 30px;

	@media only screen and (max-width: 767.98px) {
		margin-top: 20px;
		flex-direction: column;
	}
`;

const MyPageColumn = styled.div`
	width: 181px;
`;

const MyPageMenuTop = styled.div`
	width: 181px;
	height: 89px;
	background-image: url(${LeftTopMenuBackgroundImg});
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;

	display: flex;
	align-items: center;
`;

const MyPageMenuTopTitle = styled.div`
	padding-left: 20px;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 29px;
	color: #ffffff;
`;

const MenuContainer = styled.div``;

const Menu = styled.ul`
	margin-block-start: 0;
	margin-block-end: 0;
	padding-inline-start: 0;
`;

const LeftTopMenuSelectedIcon = styled.i`
	display: none;
	width: 8px;
	height: 12px;
	background-image: url(${LeftTopMenuSelectedImg});
`;

const MainMenu = styled.li`
	list-style: none;
	width: 181px;
	height: 50px;
	border: 1px solid #dddddd;

	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 20px;
	cursor: pointer;

	span {
		font-family: 'Pretendard';
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 19px;
		color: #000000;
		cursor: pointer;
	}

	${(props) =>
		props.selected &&
		css`
			border: 2px solid #0e498f;

			& > ${LeftTopMenuSelectedIcon} {
				display: block;
			}
		`}
`;

const BookingListColumn = styled.div`
	flex-grow: 1;
	margin-left: 30px;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		margin-left: 0;
		margin-top: 20px;
		padding: 0 10px;
	}
`;

const MyPageInfo = styled.div`
	display: flex;
	align-items: center;
`;

const MyPageTitle = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 30px;
	line-height: 36px;
	color: #000000;

	@media only screen and (max-width: 767.98px) {
		margin-left: 10px;
		margin-bottom: 10px;
		font-size: 20px;
		line-height: 24px;
		color: #0b2b70;
	}
`;

const UnitTypeName = styled.span`
	margin-left: 10px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 22px;
	line-height: 26px;
	color: #22499d;

	@media only screen and (max-width: 767.98px) {
		margin-bottom: 10px;
		font-size: 16px;
		line-height: 19px;
		color: #000000;
	}
`;

const MobileDesription = styled.div`
	width: 100%;
	text-align: right;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;
`;

const StatusButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;

	@media only screen and (max-width: 767.98px) {
		margin-top: 10px;
	}
`;

const StatusButton = styled.div`
	width: 80px;
	height: 32px;

	display: flex;
	align-items: center;
	justify-content: center;

	background-color: #ffffff;
	border: 1px solid #0e498f;
	border-radius: 50px;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	text-align: center;
	color: #0e499d;

	& + & {
		margin-left: 5px;
	}

	${(props) =>
		props.selected &&
		css`
			background-color: #366fb4;
			border: 1px solid #366fb4;
			color: #ffffff;
		`}
`;

const StatusSelect = styled.select`
	width: 124px;
	height: 41px;
	padding: 8px;

	border: 1px solid #dddddd;
	border-radius: 5px;

	appearance: none;
	background-image: linear-gradient(45deg, transparent 50%, #000000 50%),
		linear-gradient(135deg, #000000 50%, transparent 50%);
	background-position: calc(100% - 20px) calc(1em + 0px), calc(100% - 15px) calc(1em + 0px), calc(100% - 2.5em) 0.5em;
	background-size: 5px 5px, 5px 5px, 1px 1.5em;
	background-repeat: no-repeat;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: #333333;
`;

const TableContainer = styled.div`
	width: 100%;

	@media only screen and (max-width: 767.98px) {
		overflow: scroll;
	}
`;

const BookingListTable = styled.table`
	width: 100%;

	border-collapse: collapse;
	border-top: 2px solid #333333;
	text-align: center;
	margin-top: 12px;

	thead th {
		height: 44px;
		background-color: #f7f7f7;
		font-family: 'Pretendard';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		color: #000000;
	}
	thead tr {
		border-bottom: 1px solid #dddddd;
	}

	tbody td {
		height: 44px;
		font-family: 'Pretendard';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		color: #666666;
		word-break: break-all;
	}
	tbody td.strong {
		color: #333333;
		font-weight: 500;
	}
	tbody tr {
		border-bottom: 1px solid #dddddd;
	}

	tfoot {
		height: 45px;
		background: #f7f7f7;
		border-bottom: 1px solid #dddddd;
	}

	@media only screen and (max-width: 767.98px) {
		margin-top: 10px;
		thead th {
			font-size: 14px;
		}

		tbody td {
			font-size: 14px;
			word-break: break-all;
		}
	}
`;

const TableDataContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const ApprovalStatusText = styled.div`
	width: 66px;
	height: 24px;

	display: flex;
	align-items: center;
	justify-content: center;

	border-radius: 50px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 16px;
	color: #ffffff;

	${(props) =>
		props.status === ApprovalStatus.APPROVED &&
		css`
			background-color: #366fb4;
		`}

	${(props) =>
		(props.status === ApprovalStatus.CANCELED || props.status === ApprovalStatus.REJECTED) &&
		css`
			background-color: #e7673f;
		`}
	
	${(props) =>
		props.status === ApprovalStatus.PENDING &&
		css`
			background-color: #49bbc6;
		`}
`;

const RentalStatusText = styled.div`
	width: 66px;
	height: 24px;

	display: flex;
	align-items: center;
	justify-content: center;

	border-radius: 50px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 16px;
	color: #666666;

	${(props) =>
		props.status === RentalStatus.RENTAL_READY &&
		css`
			color: #ffffff;
			background-color: #49bbc6;
		`}

	${(props) =>
		(props.status === RentalStatus.RENTAL_COMPLETE || props.status === RentalStatus.RETURN_COMPLETE) &&
		css`
			color: #ffffff;
			background-color: #366fb4;
		`}

	${(props) =>
		props.status === RentalStatus.RENTAL_DELAY &&
		css`
			color: #ffffff;
			background-color: #e7673f;
		`}
	
	${(props) =>
		props.status === RentalStatus.RETURN_DELAY &&
		css`
			color: #ffffff;
			background-color: #dc2929;
		`}
`;

const OperatorRequestIcon = styled.img`
	width: 24px;
	height: 24px;
	cursor: pointer;
`;

const ShowBookingButton = styled.div`
	width: 50px;
	height: 24px;

	display: flex;
	align-items: center;
	justify-content: center;

	border: 1px solid #0e498f;
	border-radius: 50px;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 16px;
	color: #1d54a3;
`;

const CancelBookingButton = styled.div`
	width: 50px;
	height: 24px;

	display: flex;
	align-items: center;
	justify-content: center;

	border: 1px solid #a0a0a0;
	border-radius: 50px;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 16px;
	color: #666666;
`;

export default MyPage;
