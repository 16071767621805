import React, { useState, useEffect, useContext } from 'react';
import styled, { css } from 'styled-components';
import BookingItemModal from '../../components/modal/BookingItemModal';
import ApprovalStatus from '../../constants/ApprovalStatus';
import UnitType from '../../constants/UnitType';
import UserRole from '../../constants/UserRole';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import koLocale from '@fullcalendar/core/locales/ko';
import { UserContext } from '../../contexts/UserContext';

import * as api from '../../apis';

const DashboardCalendar = ({ unitType, standalone }) => {
	const { user } = useContext(UserContext);
	const [unitList, setUnitList] = useState(null);
	const [calendarUnitId, setCalendarUnitId] = useState(null);
	const [bookingList, setBookingList] = useState([]);

	const [bookingItemModalData, setBookingItemModalData] = useState(null);
	const [bookingItemModalVisibility, setBookingItemModalVisiblity] = useState(false);

	useEffect(() => {
		let filterParams = [`unit_type=${unitType}`, `page_size=${Number.MAX_SAFE_INTEGER}`];
		// 사용자가 관리자 권한이 없는 경우 Unit 관리 대상만 얻는다.
		if (!UserRole.isAdminRole(user?.user_role)) {
			user.unit_admins.forEach((unit) => filterParams.push(`unit_ids[]=${unit.unit_id}`));
		}

		api.getUnits(filterParams)
			.then((res) => {
				if (res && res.data) {
					setUnitList(res.data);
					setCalendarUnitId(res.data.items[0].id);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	}, []);

	useEffect(() => {
		if (!calendarUnitId) return;
		getBookingsFromApi();
	}, [calendarUnitId]);

	const getBookingsFromApi = () => {
		let filterParams = [
			`unit_id=${calendarUnitId}`,
			`approval_statuses[]=${ApprovalStatus.APPROVED}`,
			`approval_statuses[]=${ApprovalStatus.PENDING}`,
			`page_size=${Number.MAX_SAFE_INTEGER}`,
		];

		api.getBookings(filterParams)
			.then((res) => {
				if (res && res.data) {
					setBookingList(res.data);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const getBookingEvents = () => {
		return (
			bookingList.items?.map((booking) => {
				const bookingUnitType = booking.unit.unit_type_name;
				// 장비예약인 경우 시간 구분이 필요없으므로 allDay=true로 처리한다.
				// allDay를 전체 true로 하는 경우 end 시간은 포함되지 않으므로 end time에 1분을 추가하여 다음날 00시로 end 시간을 정한다.
				const allDay = bookingUnitType === UnitType.PRODUCT;
				const bookingEndDate = new Date(booking.booking_end_date);
				if (allDay) {
					bookingEndDate.setMinutes(bookingEndDate.getMinutes() + 1);
				}

				return {
					url: booking.id,
					title: `#${booking.id} ${booking.user.name}`,
					allDay: allDay,
					start: booking.booking_start_date,
					end: bookingEndDate,
					classNames: [`${unitType}_status_${booking.approval_status}`],
				};
			}) ?? []
		);
	};

	const onOpenStandalone = () => {
		window.open(`/admin/calendar/${unitType}`);
	};

	const onClickEvent = (eventInfo) => {
		eventInfo.jsEvent.preventDefault();

		let booking = bookingList.items.find((booking) => booking.id == eventInfo.event.url);
		setBookingItemModalData(booking);
		setBookingItemModalVisiblity(true);
	};

	return (
		<>
			<UnitSelectContainer>
				<UnitSelectTitle>{unitType === UnitType.ROOM ? '시설 선택' : '장비 선택'}</UnitSelectTitle>
				<UnitSelect value={calendarUnitId ?? ''} onChange={(e) => setCalendarUnitId(e.currentTarget.value)}>
					{unitList?.items.map((unit, idx) => {
						return (
							<option key={idx} value={unit.id}>
								{unit.name ?? '-'}
							</option>
						);
					})}
				</UnitSelect>
				{!standalone && <OpenStandaloneButton onClick={onOpenStandalone}>새 창으로 보기</OpenStandaloneButton>}
			</UnitSelectContainer>
			<ApprovalStatusContainer>
				<ApprovalStatusLegend>
					<ApprovalStatusCircle status="approved"></ApprovalStatusCircle>
					<ApprovalStatusText>승인됨</ApprovalStatusText>
				</ApprovalStatusLegend>
				<ApprovalStatusLegend>
					<ApprovalStatusCircle status="pending"></ApprovalStatusCircle>
					<ApprovalStatusText>승인대기</ApprovalStatusText>
				</ApprovalStatusLegend>
			</ApprovalStatusContainer>
			<CalendarContainer>
				<FullCalendar
					plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
					initialView="dayGridMonth"
					locale={koLocale}
					headerToolbar={{
						start: 'today prev,next',
						center: 'title',
						end: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
					}}
					buttonText={{
						today: '오늘',
						month: '월',
						week: '주',
						day: '일',
						list: '목록',
					}}
					fixedWeekCount={false}
					dayHeaderClassNames="day-header"
					events={getBookingEvents()}
					eventClick={(e) => onClickEvent(e)}
					height={standalone ? 'calc(100vh - 180px)' : '700px'}
				/>
			</CalendarContainer>
			{bookingItemModalVisibility && (
				<BookingItemModal
					unitType={unitType}
					booking={bookingItemModalData}
					onClose={() => {
						setBookingItemModalVisiblity(false);
					}}
				/>
			)}
		</>
	);
};

const UnitSelectContainer = styled.div`
	display: flex;
	align-items: center;
	margin-top: 20px;
`;

const UnitSelectTitle = styled.div`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #000000;
`;

const UnitSelect = styled.select`
	width: 250px;
	height: 35px;
	padding: 8px;
	margin-left: 14px;

	border: 1px solid #dddddd;
	border-radius: 5px;

	appearance: none;
	background-image: linear-gradient(45deg, transparent 50%, #000000 50%),
		linear-gradient(135deg, #000000 50%, transparent 50%);
	background-position: calc(100% - 20px) calc(1em + 0px), calc(100% - 15px) calc(1em + 0px), calc(100% - 2.5em) 0.5em;
	background-size: 5px 5px, 5px 5px, 1px 1.5em;
	background-repeat: no-repeat;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: #333333;
`;

const OpenStandaloneButton = styled.div`
	width: 110px;
	height: 33px;

	margin-left: 10px;
	background-color: #2b62ab;
	border-radius: 5px;
	cursor: pointer;

	display: flex;
	align-items: center;
	justify-content: center;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	color: #ffffff;

	&:hover {
		background-color: #0c438d;
	}
`;

const CalendarContainer = styled.div`
	margin-top: 20px;
	width: 100%;
`;

const ApprovalStatusContainer = styled.div`
	margin-top: 20px;
	display: flex;
`;

const ApprovalStatusLegend = styled.div`
	display: flex;
	align-items: center;
	& + & {
		margin-left: 20px;
	}
`;

const ApprovalStatusCircle = styled.div`
	width: 8px;
	height: 8px;
	border: 4px solid;
	border-radius: 4px;

	${(props) =>
		props.status == 'approved' &&
		css`
			border-color: #3788d8;
		`}
	${(props) =>
		props.status == 'pending' &&
		css`
			border-color: #fead0a;
		`}
`;

const ApprovalStatusText = styled.span`
	margin-left: 4px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 12px;
`;

export default DashboardCalendar;
