import React, { useState, useEffect, useContext } from 'react';
import styled, { css } from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';
import AgreementPrivacyModal from '../components/modal/AgreementPrivacyModal';
import LoadingIndicator from '../components/LoadingIndicator';
import CategorySlug from '../constants/CategorySlug';
import UnitType from '../constants/UnitType';

import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import BreadcrumbHomeImg from '../assets/images/common/breadcrumb_home.svg';
import BreadcrumbSeparatorImg from '../assets/images/common/breadcrumb_separator.svg';

import * as api from '../apis';
import * as utils from '../utils';

const BookingRequest = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { user } = useContext(UserContext);

	const [userData, setUserdata] = useState(null);
	const [unitId, setUnitId] = useState(0);
	const [unitType, setUnitType] = useState(UnitType.ROOM);
	const [unitData, setUnitData] = useState(null);
	// Room: usageDate
	const [usageDate, setUsageDate] = useState('');
	// Product: usageStartDate ~ usageEndDate
	const [usageStartDate, setUsageStartDate] = useState('');
	const [usageEndDate, setUsageEndDate] = useState('');
	const [bookingStartTime, setBookingStartTime] = useState('');
	const [bookingEndTime, setBookingEndTime] = useState('');
	const [usageCount, setUsageCount] = useState(1);
	const [purposeDescription, setPurposeDescription] = useState('');
	const [extraDescription, setExtraDescription] = useState('');
	const [attachments, setAttachments] = useState([]);
	const [agreementPrivacy, setAgreementPrivacy] = useState(false);
	const [agreementPrivacyShow, setAgreementPrivacyShow] = useState(false);
	const [contact, setContact] = useState('');
	// 운영자가 예약 대상자 정보를 변경하는 경우 사용
	const [proxyUserName, setProxyUserName] = useState('');
	const [proxyUserLogin, setProxyUserLogin] = useState('');
	const [proxyUserEmail, setProxyUserEmail] = useState('');

	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setUnitId(location.state?.unit_id ?? 0);
		// For Room
		setUsageDate(location.state?.filter?.usage_date ?? '');
		setBookingStartTime(location.state?.filter?.booking_start_time ?? '');
		setBookingEndTime(location.state?.filter?.booking_end_time ?? '');
		// For Product
		setUsageStartDate(location.state?.filter?.usage_start_date ?? '');
		setUsageEndDate(location.state?.filter?.usage_end_date ?? '');

		api.getUser('self')
			.then((res) => {
				if (res && res.data) {
					setUserdata(res.data);
					setProxyUserName(res.data?.name ?? '');
					setProxyUserLogin(res.data?.user_login ?? '');
					setProxyUserEmail(res.data?.email ?? '');
					setContact(res.data?.phone ?? '');
				}
			})
			.catch((err) => {
				console.error(err);
			});

		api.getUnit(location.state?.unit_id)
			.then((res) => {
				if (res && res.data) {
					setUnitData(res.data);
					setUnitType(res.data.unit_type_name);
				}
			})
			.catch((err) => {
				console.error(err);
				navigate(-1);
			});
	}, []);

	const onClickHome = () => {
		navigate('/');
	};

	const onClickPrev = () => {
		if (window.confirm('정말 취소하시겠습니까?')) {
			navigate('/');
		}
	};

	const onDeleteAttachmentFile = (deleteIndex) => {
		setAttachments(attachments.filter((attachment, idx) => idx !== deleteIndex));
	};

	const onClickRequest = () => {
		switch (unitType) {
			case UnitType.ROOM:
				requestRoomBooking();
				return;
			case UnitType.PRODUCT:
				requestProductBooking();
				break;
		}
	};

	const requestRoomBooking = () => {
		if (!contact) {
			alert('연락처를 입력해주세요.');
			return;
		}

		// 숫자, 하이픈만 허용
		if (!/^(\d|-)+$/.test(contact)) {
			alert('숫자와 - 만 입력 가능합니다.');
			return;
		}

		// 연락처는 7자리 이상이어야 한다.
		if (contact.length < 7) {
			alert('연락처는 7자리 이상으로 입력해주세요.');
			return;
		}

		if (!usageCount) {
			alert('사용 인원을 정확하게 입력하세요.');
			return;
		}
		if (!agreementPrivacy) {
			alert('개인정보처리방침에 동의하셔야 합니다');
			return;
		}

		const params = new FormData();
		params.append('unit_id', unitId);
		params.append('booking_start_date', convertParameterDate(usageDate, bookingStartTime));
		params.append('booking_end_date', convertParameterDate(usageDate, bookingEndTime));
		params.append('usage_count', usageCount);
		params.append('purpose_description', purposeDescription);
		params.append('extra_description', extraDescription ?? '');
		params.append('agreement_privacy', agreementPrivacy ?? '');
		params.append('contact', contact);
		// 운영자 권한으로 설정하는 경우 예약 대상 사용자를 지정해서 전달할 수 있다.
		if (checkAdminRole()) {
			params.append('proxy_user_name', proxyUserName);
			params.append('proxy_user_login', proxyUserLogin);
			params.append('proxy_user_email', proxyUserEmail);
		}

		attachments.forEach((file) => {
			params.append('attachment_files[]', file);
		});

		setIsLoading(true);
		api.createBooking(params)
			.then((res) => {
				setIsLoading(false);
				if (res && res.data) {
					navigate('/booking/complete', {
						state: {
							booking_id: res.data?.id,
							contact_number: unitData?.contact_number,
						},
					});
				}
			})
			.catch((err) => {
				setIsLoading(false);
				console.error(err);
			});
	};

	const requestProductBooking = () => {
		if (!contact) {
			alert('연락처를 입력해주세요.');
			return;
		}

		// 숫자, 하이픈만 허용
		if (!/^(\d|-)+$/.test(contact)) {
			alert('숫자와 - 만 입력 가능합니다.');
			return;
		}

		// 연락처는 7자리 이상이어야 한다.
		if (contact.length < 7) {
			alert('연락처는 7자리 이상으로 입력해주세요.');
			return;
		}

		if (!usageCount) {
			alert('예약 수량을 정확하게 입력하세요.');
			return;
		}
		if (!agreementPrivacy) {
			alert('개인정보처리방침에 동의하셔야 합니다');
			return;
		}

		const params = new FormData();
		params.append('unit_id', unitId);
		params.append('booking_start_date', convertParameterDate(usageStartDate, '00:00'));
		params.append('booking_end_date', convertParameterDate(usageEndDate, '23:59'));
		params.append('usage_count', usageCount);
		params.append('purpose_description', purposeDescription);
		params.append('extra_description', extraDescription ?? '');
		params.append('agreement_privacy', agreementPrivacy ?? '');
		params.append('contact', contact);
		// 운영자 권한으로 설정하는 경우 예약 대상 사용자를 지정해서 전달할 수 있다.
		if (checkAdminRole()) {
			params.append('proxy_user_name', proxyUserName);
			params.append('proxy_user_login', proxyUserLogin);
			params.append('proxy_user_email', proxyUserEmail);
		}

		setIsLoading(true);
		api.createBooking(params)
			.then((res) => {
				setIsLoading(false);
				if (res && res.data) {
					navigate('/booking/complete', {
						state: {
							booking_id: res.data?.id,
							contact_number: unitData?.contact_number,
						},
					});
				}
			})
			.catch((err) => {
				setIsLoading(false);
				console.error(err);
			});
	};

	/**
	 * FIXME: 현재 로직으로는 usageDate를 기준으로 시간 계산을 하고 있어서
	 * 현재 날짜~ 다음 날짜까지 예약 하는 경우 데이터가 오류가 생길 수 있다
	 * 새벽까지 예약하는 경우는 없을 것이라 생각되므로 일단은 넘어가고 추후 고치자
	 * @param {*} d
	 * @param {*} time
	 * @returns
	 */
	const convertParameterDate = (d, time) => {
		let date = new Date(d);
		let splitedTime = time.split(':');

		date.setHours(splitedTime[0]);
		date.setMinutes(splitedTime[1]);

		return date.toISOString();
	};

	const categorySlugPlace = unitType === UnitType.ROOM ? CategorySlug.PLACE : CategorySlug.PRODUCT_PLACE;
	const categorySlugType = unitType === UnitType.ROOM ? CategorySlug.TYPE : CategorySlug.PRODUCT_TYPE;

	const checkAdminRole = () => {
		return unitType === UnitType.ROOM
			? utils.checkRoomAdminRole(user, Number(unitId))
			: utils.checkProductAdminRole(user, Number(unitId));
	};

	const renderEditableUserInfo = () => {
		return (
			<InfoContainer>
				<InfoRow>
					<InfoColumn col2>
						<InfoTitle>예약자</InfoTitle>
						<InfoDescription noPadding>
							<UserInfoInput
								type="input"
								value={proxyUserName}
								onChange={(e) => setProxyUserName(e.currentTarget.value)}
							/>
						</InfoDescription>
					</InfoColumn>
					<InfoColumn col2>
						<InfoTitle>학번/교직원 번호</InfoTitle>
						<InfoDescription noPadding>
							<UserInfoInput
								type="input"
								value={proxyUserLogin}
								onChange={(e) => setProxyUserLogin(e.currentTarget.value)}
							/>
						</InfoDescription>
					</InfoColumn>
				</InfoRow>
				<InfoRow>
					<InfoColumn col2>
						<InfoTitle>이메일</InfoTitle>
						<InfoDescription noPadding>
							<UserInfoInput
								type="input"
								value={proxyUserEmail}
								onChange={(e) => setProxyUserEmail(e.currentTarget.value)}
							/>
						</InfoDescription>
					</InfoColumn>
					<InfoColumn col2>
						<InfoTitle large>
							연락처<InfoRequirment>(필수)</InfoRequirment>
						</InfoTitle>
						<InfoDescription noPadding>
							<UserInfoInput
								type="input"
								value={contact}
								onChange={(e) => setContact(e.currentTarget.value)}
							/>
						</InfoDescription>
					</InfoColumn>
				</InfoRow>
			</InfoContainer>
		);
	};

	const renderUserInfo = () => {
		return (
			<InfoContainer>
				<InfoRow>
					<InfoColumn col2>
						<InfoTitle>예약자</InfoTitle>
						<InfoDescription>{userData?.name ?? '-'}</InfoDescription>
					</InfoColumn>
					<InfoColumn col2>
						<InfoTitle>학번/교직원 번호</InfoTitle>
						<InfoDescription>{userData?.user_login ?? '-'}</InfoDescription>
					</InfoColumn>
				</InfoRow>
				<InfoRow>
					<InfoColumn col2>
						<InfoTitle>이메일</InfoTitle>
						<InfoDescription>{userData?.email ?? '-'}</InfoDescription>
					</InfoColumn>
					<InfoColumn col2>
						<InfoTitle large>
							연락처<InfoRequirment>(필수)</InfoRequirment>
						</InfoTitle>
						<InfoDescription noPadding>
							<UserInfoInput
								type="input"
								value={contact}
								onChange={(e) => setContact(e.currentTarget.value)}
							/>
						</InfoDescription>
					</InfoColumn>
				</InfoRow>
			</InfoContainer>
		);
	};

	return (
		<Content>
			<LoadingIndicator size={50} loading={isLoading} />
			<TitleContainer>
				<Title>신청서 작성</Title>
				<Breadcrumb>
					<HomeIcon src={BreadcrumbHomeImg} onClick={onClickHome} />
					<BreadcrumbSeparator src={BreadcrumbSeparatorImg} />
					<BreadcrumbText>예약/신청</BreadcrumbText>
					<BreadcrumbSeparator src={BreadcrumbSeparatorImg} />
					<BreadcrumbText>예약신청</BreadcrumbText>
					<BreadcrumbSeparator src={BreadcrumbSeparatorImg} />
					<BreadcrumbText>{unitType === UnitType.ROOM ? '시설예약' : '장비예약'}</BreadcrumbText>
					<BreadcrumbSeparator src={BreadcrumbSeparatorImg} />
					<BreadcrumbText current>신청서 작성</BreadcrumbText>
				</Breadcrumb>
			</TitleContainer>
			<Section>
				<SectionTitle>신청자 정보</SectionTitle>
				{checkAdminRole() ? renderEditableUserInfo() : renderUserInfo()}
			</Section>
			<Section>
				<SectionTitle>예약정보</SectionTitle>
				<InfoContainer>
					<InfoRow>
						<InfoColumn col3>
							<InfoTitle>대학</InfoTitle>
							<InfoDescription>
								{utils.getUnitCategoryName(unitData, categorySlugPlace, 1)}
							</InfoDescription>
						</InfoColumn>
						<InfoColumn col3>
							<InfoTitle>캠퍼스</InfoTitle>
							<InfoDescription>
								{utils.getUnitCategoryName(unitData, categorySlugPlace, 2)}
							</InfoDescription>
						</InfoColumn>
						<InfoColumn col3>
							<InfoTitle>건물</InfoTitle>
							<InfoDescription>
								{utils.getUnitCategoryName(unitData, categorySlugPlace, 3)}
							</InfoDescription>
						</InfoColumn>
					</InfoRow>
					<InfoRow>
						<InfoColumn col3>
							<InfoTitle>{unitType === UnitType.ROOM ? '시설유형' : '장비유형'}</InfoTitle>
							<InfoDescription>
								{utils.getUnitCategoryName(unitData, categorySlugType, 2)}
							</InfoDescription>
						</InfoColumn>
						<InfoColumn col3>
							<InfoTitle>{unitType === UnitType.ROOM ? '시설명' : '장비명'}</InfoTitle>
							<InfoDescription>{unitData?.name}</InfoDescription>
						</InfoColumn>
					</InfoRow>
					<InfoRow>
						<InfoColumn col1>
							<InfoTitle>이용기간</InfoTitle>
							{unitType === UnitType.ROOM && (
								<InfoDescription>{`${usageDate} ${bookingStartTime} ~ ${bookingEndTime}`}</InfoDescription>
							)}
							{unitType === UnitType.PRODUCT && (
								<InfoDescription>{`(대여일) ${usageStartDate} ~ (반납일) ${usageEndDate}`}</InfoDescription>
							)}
						</InfoColumn>
					</InfoRow>
				</InfoContainer>
			</Section>
			<Section>
				<SectionTitle>신청정보</SectionTitle>
				<InfoContainer>
					<InfoRow input>
						<InfoColumn col1>
							{unitType === UnitType.ROOM && (
								<>
									<InfoTitle>사용인원</InfoTitle>
									<UserInputContainer>
										<UsageCountInput
											type="number"
											value={usageCount}
											onChange={(e) => setUsageCount(e.currentTarget.value)}
										/>
										명
										<UserInputDescription>{`최대 사용 인원 : ${
											unitData?.room_detail?.max_usage_count ?? 0
										}명`}</UserInputDescription>
									</UserInputContainer>
								</>
							)}
							{unitType === UnitType.PRODUCT && (
								<>
									<InfoTitle>예약수량</InfoTitle>
									<UserInputContainer>
										<UsageCountInput
											type="number"
											value={usageCount}
											onChange={(e) => setUsageCount(e.currentTarget.value)}
										/>
										개
										<UserInputDescription>{`(최대 예약 가능 수량 : ${
											unitData?.product_detail?.max_rental_count ?? 0
										}개)`}</UserInputDescription>
									</UserInputContainer>
								</>
							)}
						</InfoColumn>
					</InfoRow>
					<InfoRow input>
						<InfoColumn col1>
							<InfoTitle>{unitType === UnitType.ROOM ? '촬영내용' : '이용목적'}</InfoTitle>
							<UserInputContainer>
								<UserTextArea
									value={purposeDescription}
									rows={4}
									onChange={(e) => setPurposeDescription(e.currentTarget.value)}
								/>
							</UserInputContainer>
						</InfoColumn>
					</InfoRow>
					<InfoRow input>
						<InfoColumn col1>
							<InfoTitle>특이사항</InfoTitle>
							<UserInputContainer>
								<UserTextArea
									value={extraDescription}
									rows={4}
									onChange={(e) => setExtraDescription(e.currentTarget.value)}
								/>
							</UserInputContainer>
						</InfoColumn>
					</InfoRow>
					{unitType === UnitType.ROOM && (
						<InfoRow input>
							<InfoColumn col1>
								<InfoTitle>교안파일</InfoTitle>
								<UserInputContainer>
									<AttachmentContainer>
										<AttachmentInput
											type="file"
											onChange={(e) => {
												setAttachments([...attachments, ...e.currentTarget.files]);
												e.currentTarget.value = null;
											}}
										/>
										{attachments.map((val, idx) => {
											return (
												<AttachmentFile key={idx}>
													{val.name}
													<StyledHighlightOffIcon
														onClick={() => onDeleteAttachmentFile(idx)}
													/>
												</AttachmentFile>
											);
										})}
									</AttachmentContainer>
								</UserInputContainer>
							</InfoColumn>
						</InfoRow>
					)}
				</InfoContainer>
			</Section>
			<Section>
				<SectionTitle>개인정보수집·제공</SectionTitle>
				<AgreementContainer>
					<Agreement onClick={() => setAgreementPrivacyShow(true)}>개인정보 수집·제공 동의</Agreement>
					<AgreementCheckboxContainer>
						<AgreementCheckbox
							id="agreement_privacy"
							type="checkbox"
							value={agreementPrivacy}
							onChange={(e) => setAgreementPrivacy(e.currentTarget.checked)}
						/>
						<label htmlFor={'agreement_privacy'}>모두 동의합니다.</label>
					</AgreementCheckboxContainer>
				</AgreementContainer>
			</Section>
			<ButtonContainer>
				<PrevButton onClick={onClickPrev}>취소</PrevButton>
				<RequestButton onClick={onClickRequest}>예약신청</RequestButton>
			</ButtonContainer>
			{agreementPrivacyShow && <AgreementPrivacyModal onClose={() => setAgreementPrivacyShow(false)} />}
		</Content>
	);
};

const Content = styled.div`
	width: 100%;
	max-width: 1240px;
	padding: 40px 30px 75px 30px;
	background-color: white;
	margin-top: 50px;
	margin-bottom: 109px;

	@media only screen and (max-width: 767.98px) {
		width: calc(100% - 20px);
		margin: 10px;
		padding: 15px 10px;
	}
`;

const TitleContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media only screen and (max-width: 767.98px) {
		flex-direction: column;
	}
`;

const Title = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 36px;
	line-height: 43px;
	color: #000000;

	@media only screen and (max-width: 767.98px) {
		align-self: flex-start;
		font-size: 24px;
		line-height: 29px;
	}
`;

const Breadcrumb = styled.div`
	display: flex;
	justify-content: flex-end;

	@media only screen and (max-width: 767.98px) {
		margin-top: 20px;
		justify-content: center;
	}
`;

const HomeIcon = styled.img`
	cursor: pointer;
`;

const BreadcrumbSeparator = styled.img`
	margin: 0 10px;

	@media only screen and (max-width: 767.98px) {
		margin: 0 5px;
	}
`;

const BreadcrumbText = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;

	${(props) =>
		props.current &&
		css`
			color: #22499d;
		`}

	@media only screen and (max-width: 767.98px) {
		font-size: 12px;
		line-height: 14px;
	}
`;

const Section = styled.div`
	margin-top: 30px;

	@media only screen and (max-width: 767.98px) {
		margin-top: 20px;
	}
`;

const SectionTitle = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 26px;
	color: #000000;

	@media only screen and (max-width: 767.98px) {
		font-size: 16px;
		line-height: 19px;
	}
`;

const InfoContainer = styled.div`
	width: 100%;
	margin-top: 20px;
	border-top: 2px solid #333333;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		margin-left: 0;
		margin-top: 10px;
	}
`;

const InfoRow = styled.div`
	display: flex;
	width: 100%;
	border-bottom: 1px solid #dddddd;

	@media only screen and (max-width: 767.98px) {
		flex-direction: column;
		padding: 0;
		border-bottom: none;
	}
`;

const InfoColumn = styled.div`
	display: flex;
	height: 100%;

	${(props) =>
		props.col1 &&
		css`
			width: 100%;
		`}
	${(props) =>
		props.col2 &&
		css`
			width: 50%;
		`}
	${(props) =>
		props.col3 &&
		css`
			width: 33.33%;
		`}

	@media only screen and (max-width: 767.98px) {
		flex-direction: column;
		width: 100%;
		min-height: auto;
		border-bottom: 1px solid #dddddd;
	}
`;

const InfoTitle = styled.div`
	display: flex;
	align-items: center;
	padding: 15px 20px;
	width: 152px;
	background-color: #f7f7f7;

	${(props) =>
		props.top &&
		css`
			align-items: flex-start;
			padding-top: 5px;
		`}

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #000000;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		padding: 10px 0 0 10px;
		background-color: #ffffff;
	}
`;

const InfoRequirment = styled.span`
	margin-left: 5px;
	color: #dc3545;
`;

const InfoDescription = styled.div`
	display: flex;
	align-items: center;
	padding: 15px 20px;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;

	${(props) =>
		props.noPadding &&
		css`
			padding: 0 20px;
		`}

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		padding: 6px 0 11px 10px;
	}
`;

const UserInputContainer = styled.div`
	width: 90%;
	display: flex;
	align-items: center;
	padding: 5px 20px;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		padding: 6px 10px 12px 10px;
	}
`;

const UserInput = styled.input`
	height: 33px;
	padding: 8px;
	border: 1px solid #dddddd;
	border-radius: 5px;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;
`;

const UsageCountInput = styled(UserInput)`
	width: 100px;
	margin-right: 5px;

	@media only screen and (max-width: 767.98px) {
		width: 50px;
		height: 32px;
		align-self: center;
	}
`;

const UserInfoInput = styled(UserInput)`
	width: 200px;

	@media only screen and (max-width: 767.98px) {
		width: 200px;
		height: 32px;
		align-self: center;
	}
`;

const UserInputDescription = styled.div`
	margin-left: 5px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #22499d;

	@media only screen and (max-width: 767.98px) {
		font-size: 14px;
		line-height: 17px;
	}
`;

const UserTextArea = styled.textarea`
	width: 100%;
	padding: 8px;
	border: 1px solid #dddddd;
	border-radius: 5px;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;

	@media only screen and (max-width: 767.98px) {
		margin-top: 8px;
		margin-bottom: 8px;
	}
`;

const AttachmentContainer = styled.div`
	display: flex;
	flex-direction: column;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;

	input:not(:first-child) {
		margin-top: 8px;
	}
`;

const AttachmentFile = styled.div`
	margin-top: 8px;
`;

const StyledHighlightOffIcon = styled(HighlightOffIcon)`
	margin-left: 8px;
	cursor: pointer;
`;

const AttachmentInput = styled.input`
	@media only screen and (max-width: 767.98px) {
		width: 100%;
		margin-top: 8px;
		& + & {
			margin-bottom: 8px;
		}
	}
`;

const AgreementContainer = styled.div`
	display: flex;
	align-items: center;
	padding: 5px 13px;
	width: 100%;
	margin-top: 20px;
	border-top: 2px solid #333333;
	border-bottom: 1px solid #dddddd;

	@media only screen and (max-width: 767.98px) {
		flex-direction: column;
	}
`;

const Agreement = styled.div`
	display: flex;
	align-items: center;

	padding: 8px 15px;
	background-color: #2f5a8c;
	border-radius: 5px;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #ffffff;
`;

const AgreementCheckboxContainer = styled.div`
	margin-left: 30px;
	display: flex;
	align-items: center;

	@media only screen and (max-width: 767.98px) {
		margin-left: 0;
		margin-top: 10px;
		margin-bottom: 5px;
	}
`;

const AgreementCheckbox = styled.input`
	& + label {
		margin-left: 9px;
		font-family: 'Pretendard';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		color: #333333;
	}
`;

const ButtonContainer = styled.div`
	width: 100%;
	margin-top: 36px;
	display: flex;
	justify-content: center;
	align-items: center;

	@media only screen and (max-width: 767.98px) {
		margin-top: 30px;
	}
`;

const PrevButton = styled.div`
	width: 125px;
	height: 50px;
	background-color: #ffffff;
	border: 1px solid #22499d;
	border-radius: 5px;
	cursor: pointer;

	display: flex;
	align-items: center;
	justify-content: center;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;
	display: flex;
	align-items: center;
	color: #22499d;

	&:hover {
		background-color: #0c438d;
		color: #ffffff;
	}
`;

const RequestButton = styled.div`
	margin-left: 10px;
	width: 125px;
	height: 50px;
	background-color: #22499d;
	border-radius: 5px;
	cursor: pointer;

	display: flex;
	align-items: center;
	justify-content: center;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;
	color: #ffffff;

	&:hover {
		background-color: #0c438d;
	}
`;

export default BookingRequest;
