import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { usePrompt } from '../../../hooks/blocker';
import { Editor } from '@tinymce/tinymce-react';
import * as api from '../../../apis';
import PopupPublishType from '../../../constants/PopupPublishType';
import PopupCloseType from '../../../constants/PopupCloseType';
import AdminSectionTitle from '../../../components/admin/AdminSectionTitle';

const Popup = () => {
	const navigate = useNavigate();
	const { popup_id } = useParams();
	const isEdit = popup_id ? true : false;

	const descriptionEditorRef = useRef();

	const [title, setTitle] = useState('');
	const [publishType, setPublishType] = useState(PopupPublishType.PERIOD);
	const [publishStartDate, setPublishStartDate] = useState(null);
	const [publishEndDate, setPublishEndDate] = useState(null);
	const [width, setWidth] = useState(400);
	const [height, setHeight] = useState(600);
	const [left, setLeft] = useState(100);
	const [top, setTop] = useState(50);
	const [description, setDescription] = useState('');
	const [defaultDescription, setDefaultDescription] = useState('');
	const [closeType, setCloseType] = useState(PopupCloseType.NONE);
	const [isModify, setIsModify] = useState(false);

	usePrompt('수정한 내역을 저장하지 않고\n페이지를 벗어나시겠습니까?', isModify);

	useEffect(() => {
		if (isEdit) {
			api.getPopup(popup_id)
				.then((res) => {
					if (res && res.data) {
						setEditData(res.data);
					}
				})
				.catch((err) => {
					console.error(err);
					alert('팝업 정보에 문제가 발생하였습니다.');
					navigate(-1);
				});
		}
	}, []);

	const setEditData = (data) => {
		setTitle(data.title);
		setPublishType(data.publish_type);
		if (data.publish_type === PopupPublishType.PERIOD) {
			setPublishStartDate(data.publish_start_date);
			setPublishEndDate(data.publish_end_date);
		}
		setWidth(data.width);
		setHeight(data.height);
		setLeft(data.left);
		setTop(data.top);
		setDescription(data.description);
		setDefaultDescription(data.description);
		setCloseType(data.close_type);
		setIsModify(false);
	};

	const onEditorImageUpload = (callback, value, meta) => {
		let input = document.createElement('input');
		input.setAttribute('type', 'file');
		input.setAttribute('accept', 'image/*');
		input.onchange = function () {
			let file = this.files[0];
			(async () => {
				let formData = new FormData();
				formData.append('file', file);
				api.createFile(formData)
					.then((res) => {
						let url = `${process.env.REACT_APP_API_URL}/storage/files/${res.data.filename}`;
						callback(url, { alt: file.name, title: file.name });
					})
					.catch((err) => {
						console.error(err);
					});
			})();
		};
		input.click();
	};

	const onClickSave = () => {
		const data = {
			title,
			description,
			publish_type: publishType,
			publish_start_date: publishType === PopupPublishType.PERIOD ? publishStartDate : null,
			publish_end_date: publishType === PopupPublishType.PERIOD ? publishEndDate : null,
			width: Number(width),
			height: Number(height),
			left: Number(left),
			top: Number(top),
			close_type: closeType,
		};

		if (!validateData(data, isEdit)) {
			return;
		}

		if (isEdit) {
			setIsModify(false);
			api.updatePopup(popup_id, data)
				.then((res) => {
					if (res && res.data) {
						alert('수정되었습니다.');
						navigate('/admin/settings/popups');
					}
				})
				.catch((err) => {
					console.error(err);
				});
		} else {
			setIsModify(false);
			api.createPopup(data)
				.then((res) => {
					if (res && res.data) {
						alert('저장되었습니다.');
						navigate('/admin/settings/popups');
					}
				})
				.catch((err) => {
					console.error(err);
				});
		}
	};

	const validateData = (data, isEdit) => {
		if (!data.title) {
			alert('팝업 제목을 입력하여 주십시오.');
			return false;
		}
		if (!data.description) {
			alert('팝업 내용을 입력하여 주십시오.');
			return false;
		}
		if (data.publish_type === PopupPublishType.PERIOD && !data.publish_start_date) {
			alert('팝업 시작일을 입력하여 주십시오.');
			return false;
		}
		if (data.publish_type === PopupPublishType.PERIOD && !data.publish_end_date) {
			alert('팝업 종료일을 입력하여 주십시오.');
			return false;
		}

		return true;
	};

	return (
		<Content>
			<TitleText>{isEdit ? '팝업 수정' : '신규 팝업 등록'}</TitleText>
			<section className="mt-40px">
				<AdminSectionTitle title="팝업 등록 정보" />
			</section>
			<section>
				<PopupInfoContainer className="mt-24px">
					<PopupInfoRow>
						<PopupInfoTitle required>제목</PopupInfoTitle>
						<PopupInfoContents>
							<PopupInfoTextInput
								fullWidth
								type="text"
								value={title}
								onChange={(e) => {
									setIsModify(true);
									setTitle(e.currentTarget.value);
								}}
								placeholder="팝업 제목을 입력하세요"
							/>
						</PopupInfoContents>
					</PopupInfoRow>
					<PopupInfoRow>
						<PopupInfoTitle required>팝업 공개 설정</PopupInfoTitle>
						<PopupInfoContents flexCol>
							<Row>
								<RadioContainer>
									<RadioBoxGroup>
										<input
											type="radio"
											id={`publish_type_${PopupPublishType.PERIOD}`}
											name={`publish_type_${PopupPublishType.PERIOD}`}
											checked={publishType === PopupPublishType.PERIOD}
											onChange={() => {
												setIsModify(true);
												setPublishType(PopupPublishType.PERIOD);
											}}
										/>
										<label htmlFor={`publish_type_${PopupPublishType.PERIOD}`}>기간 설정</label>
									</RadioBoxGroup>
									<RadioBoxGroup>
										<input
											type="radio"
											id={`publish_type_${PopupPublishType.ALWAYS}`}
											name={`publish_type_${PopupPublishType.ALWAYS}`}
											checked={publishType === PopupPublishType.ALWAYS}
											onChange={() => {
												setIsModify(true);
												setPublishType(PopupPublishType.ALWAYS);
											}}
										/>
										<label htmlFor={`publish_type_${PopupPublishType.ALWAYS}`}>상시 게시</label>
									</RadioBoxGroup>
									<RadioBoxGroup>
										<input
											type="radio"
											id={`publish_type_${PopupPublishType.NONE}`}
											name={`publish_type_${PopupPublishType.NONE}`}
											checked={publishType === PopupPublishType.NONE}
											onChange={() => {
												setIsModify(true);
												setPublishType(PopupPublishType.NONE);
											}}
										/>
										<label htmlFor={`publish_type_${PopupPublishType.NONE}`}>비공개</label>
									</RadioBoxGroup>
								</RadioContainer>
							</Row>
							{publishType === PopupPublishType.PERIOD && (
								<Row>
									<DateContainer>
										<DateWrapper>
											<ContentsTitleText>시작일</ContentsTitleText>
											<DateInput
												type="date"
												value={publishStartDate ?? ''}
												onChange={(e) => {
													setIsModify(true);
													setPublishStartDate(e.currentTarget.value);
												}}
											/>
										</DateWrapper>
										<DateInputSeparator>~</DateInputSeparator>
										<DateWrapper>
											<ContentsTitleText>종료일</ContentsTitleText>
											<DateInput
												type="date"
												value={publishEndDate ?? ''}
												onChange={(e) => {
													setIsModify(true);
													setPublishEndDate(e.currentTarget.value);
												}}
											/>
										</DateWrapper>
									</DateContainer>
								</Row>
							)}
						</PopupInfoContents>
					</PopupInfoRow>
					<PopupInfoRow>
						<PopupInfoTitle required>팝업 크기</PopupInfoTitle>
						<PopupInfoContents>
							<ContentsTitleText>가로</ContentsTitleText>
							<PopupInfoTextInput
								type="text"
								value={width}
								onChange={(e) => {
									setIsModify(true);
									setWidth(e.currentTarget.value);
								}}
							/>
							<ContentsTitleText marginLeft>px</ContentsTitleText>
							<ContentsTitleText marginLeft>세로</ContentsTitleText>
							<PopupInfoTextInput
								type="text"
								value={height}
								onChange={(e) => {
									setIsModify(true);
									setHeight(e.currentTarget.value);
								}}
							/>
							<ContentsTitleText marginLeft>px</ContentsTitleText>
						</PopupInfoContents>
					</PopupInfoRow>
					<PopupInfoRow>
						<PopupInfoTitle required>팝업창 위치</PopupInfoTitle>
						<PopupInfoContents>
							<ContentsTitleText>왼쪽에서</ContentsTitleText>
							<PopupInfoTextInput
								type="text"
								value={left}
								onChange={(e) => {
									setIsModify(true);
									setLeft(e.currentTarget.value);
								}}
							/>
							<ContentsTitleText marginLeft>px</ContentsTitleText>
							<ContentsTitleText marginLeft>윗쪽에서</ContentsTitleText>
							<PopupInfoTextInput
								type="text"
								value={top}
								onChange={(e) => {
									setIsModify(true);
									setTop(e.currentTarget.value);
								}}
							/>
							<ContentsTitleText marginLeft>px</ContentsTitleText>
						</PopupInfoContents>
					</PopupInfoRow>
					<PopupInfoRow>
						<PopupInfoTitle required>팝업 내용/이미지 등록</PopupInfoTitle>
						<PopupInfoContents>
							<DescriptionEditorContainer>
								<Editor
									apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
									init={{
										language: 'ko_KR',
										language_url: '/libs/tinymce/langs/ko_KR.js',
										height: '100%',
										menubar: false,
										branding: false,
										plugins: [
											'advlist autolink lists link image charmap print preview anchor',
											'searchreplace visualblocks code fullscreen',
											'insertdatetime media table paste code help wordcount',
										],
										toolbar:
											'undo redo | formatselect | ' +
											'bold italic underline forecolor backcolor | alignleft aligncenter ' +
											'alignright alignjustify | bullist numlist outdent indent | ' +
											'image | help',
										content_style:
											'body { font-family:"Noto Sans"; Helvetica,Arial,sans-serif; font-size:16px }',
										image_title: false,
										automatic_uploads: true,
										convert_urls: false,
										file_picker_types: 'image',
										file_picker_callback: onEditorImageUpload,
									}}
									initialValue={defaultDescription}
									value={description}
									onInit={(evt, editor) => (descriptionEditorRef.current = editor)}
									onEditorChange={(newValue, editor) => {
										setIsModify(true);
										setDescription(newValue);
									}}
								/>
							</DescriptionEditorContainer>
						</PopupInfoContents>
					</PopupInfoRow>
					<PopupInfoRow>
						<PopupInfoTitle>팝업 닫기 설정</PopupInfoTitle>
						<PopupInfoContents>
							<RadioContainer>
								<RadioBoxGroup>
									<input
										type="radio"
										id={`close_type_${PopupCloseType.NO_MORE_TODAY}`}
										name={`close_type_${PopupCloseType.NO_MORE_TODAY}`}
										checked={closeType === PopupCloseType.NO_MORE_TODAY}
										onChange={() => {
											setIsModify(true);
											setCloseType(PopupCloseType.NO_MORE_TODAY);
										}}
									/>
									<label htmlFor={`close_type_${PopupCloseType.NO_MORE_TODAY}`}>
										오늘 하루 보지 않기
									</label>
								</RadioBoxGroup>
								<RadioBoxGroup>
									<input
										type="radio"
										id={`close_type_${PopupCloseType.NEVER}`}
										name={`close_type_${PopupCloseType.NEVER}`}
										checked={closeType === PopupCloseType.NEVER}
										onChange={() => {
											setIsModify(true);
											setCloseType(PopupCloseType.NEVER);
										}}
									/>
									<label htmlFor={`close_type_${PopupCloseType.NEVER}`}>다시 열지 않음</label>
								</RadioBoxGroup>
								<RadioBoxGroup>
									<input
										type="radio"
										id={`close_type_${PopupCloseType.NONE}`}
										name={`close_type_${PopupCloseType.NONE}`}
										checked={closeType === PopupCloseType.NONE}
										onChange={() => {
											setIsModify(true);
											setCloseType(PopupCloseType.NONE);
										}}
									/>
									<label htmlFor={`close_type_${PopupCloseType.NONE}`}>설정 안함</label>
								</RadioBoxGroup>
							</RadioContainer>
						</PopupInfoContents>
					</PopupInfoRow>
				</PopupInfoContainer>
			</section>
			<section className="mt-30px">
				<FooterContainer>
					<CancelButton onClick={() => navigate(-1)}>취소</CancelButton>
					<OkButton onClick={onClickSave}>저장</OkButton>
				</FooterContainer>
			</section>
		</Content>
	);
};

const Content = styled.div`
	width: 100%;
	max-width: 1240px;
	padding: 40px;
	background-color: white;
`;

const Row = styled.div`
	display: flex;
	align-items: center;
`;

const TitleText = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 26px;
	color: #000000;
`;

const PopupInfoContainer = styled.div`
	width: 100%;
	border-top: 2px solid #333333;
`;

const PopupInfoRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: stretch;

	min-height: 45px;
	border-bottom: 1px solid #dddddd;
	border-left: 1px solid #dddddd;
	border-right: 1px solid #dddddd;
`;

const PopupInfoTitle = styled.div`
	width: 200px;
	flex-shrink: 0;
	padding-left: 20px;
	display: flex;
	align-items: center;
	justify-content: flex-start;

	background-color: #f7f7f7;
	border-right: 1px solid #dddddd;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #333333;

	${(props) =>
		props.required &&
		css`
			::after {
				content: '*';
				color: #dc3545;
				margin-left: 2px;
			}
		`}
`;

const PopupInfoContents = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-grow: 1;

	padding: 8px 15px;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #333333;

	${(props) =>
		props.flexCol &&
		css`
			flex-direction: column;
			align-items: flex-start;
		`}
`;

const PopupInfoTextInput = styled.input`
	width: 100px;
	height: 35px;
	padding: 8px;

	border: 1px solid #dddddd;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;

	${(props) =>
		props.fullWidth &&
		css`
			width: 100%;
		`}
`;

const RadioContainer = styled.div`
	display: flex;
	margin-top: 6px;
`;

const RadioBoxGroup = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	& + & {
		margin-left: 20px;
	}

	input {
		cursor: pointer;
		width: 15px;
		height: 15px;
	}

	label {
		cursor: pointer;
		margin-left: 6px;
		font-family: 'Pretendard';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		color: #666666;
	}
`;

const ContentsTitleText = styled.div`
	display: flex;
	align-items: center;
	margin-right: 12px;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;

	${(props) =>
		props.marginLeft &&
		css`
			margin-left: 8px;
		`}
`;

const DateContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 12px;
`;

const DateWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const DateInputSeparator = styled.div`
	margin-left: 10px;
	margin-right: 10px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;
`;

const DateInput = styled.input`
	width: 150px;
	height: 35px;
	padding: 8px;

	border: 1px solid #dddddd;
	background-color: #ffffff;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;
`;

const DescriptionEditorContainer = styled.div`
	width: 100%;
	height: 600px;
`;

const FooterContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const CancelButton = styled.div`
	width: 100px;
	height: 40px;

	border: 1px solid #00b1a6;
	border-radius: 5px;

	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;
	display: flex;
	align-items: center;
	color: #009289;
`;

const OkButton = styled.div`
	width: 100px;
	height: 40px;
	margin-left: 10px;

	background-color: #00b1a6;
	border-radius: 5px;

	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;
	display: flex;
	align-items: center;
	color: #ffffff;

	&:hover {
		background-color: #008f87;
	}
`;

export default Popup;
