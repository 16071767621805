import React, { useContext, useState } from 'react';
import styled, { css } from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';
import useSizeDetector from '../hooks/useSizeDetector';

import MobileMenuImg from '../assets/images/components/header/mobile_menu.svg';
import MobileCalendarImg from '../assets/images/components/header/mobile_calendar.svg';
import MobileLogoutImg from '../assets/images/components/header/mobile_logout.svg';

import LogoImg from '../custom/images/logo.png';
import LogoMobileImg from '../custom/images/logo_mobile.png';
import CustomResource from '../custom/resource';

import UserRole from '../constants/UserRole';

import * as api from '../apis';

const Header = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { Desktop, Mobile } = useSizeDetector();

	const [showMobileMenu, setShowMobileMenu] = useState(false);

	const { user, logout } = useContext(UserContext);
	const menus = [
		{
			path: '/unit/rooms/introduce',
			link: '/unit/rooms/introduce',
			title: '안내 및 소개',
		},
		{
			path: '/booking',
			link: '/booking',
			title: '예약/신청',
		},
		{
			path: '/boards',
			link: '/boards/notice',
			title: '게시판',
		},
		{
			path: '/mypage',
			link: '/mypage',
			title: '마이페이지',
		},
	];

	const onClickLogo = () => {
		navigate('/');
	};

	const onClickLogin = () => {
		navigate('/login');
	};

	const onClickLogout = async () => {
		try {
			const res = await api.logout();
			if (res && res.status === 204) {
				logout();
				navigate('/');
			}
		} catch (err) {
			console.error(err);
		}
	};

	const onClickAdminPage = () => {
		// 관리자 권한별 이동
		if (UserRole.isSystemAdminRole(user.user_role)) {
			navigate('/admin/dashboard/room');
			return;
		} else if (UserRole.isRoomAdminRole(user.user_role)) {
			navigate('/admin/dashboard/room');
			return;
		} else if (UserRole.isProductAdminRole(user.user_role)) {
			navigate('/admin/dashboard/product');
			return;
		}

		// Unit 관리자로 배정된 사용자 권한별 이동
		if (UserRole.isRoomUnitAdmin(user.user_role, user.unit_admins)) {
			navigate('/admin/dashboard/room');
			return;
		} else if (UserRole.isProductUnitAdmin(user.user_role, user.unit_admins)) {
			navigate('/admin/dashboard/product');
			return;
		} else {
			// 예약 항목 관리자이지만 배정된 항목이 없는 경우
			alert('관리자로 등록된 예약 항목이 없습니다.');
		}
	};

	const onClickMobileMenu = () => {
		setShowMobileMenu(!showMobileMenu);
	};

	const renderLoginButton = () => {
		if (!user) {
			return <LoginButton onClick={onClickLogin}>로그인</LoginButton>;
		}

		// 관리자 권한이나 Unit 관리자로 배정된 경우
		if (UserRole.isAdminRole(user?.user_role) || UserRole.isUnitAdminRole(user?.user_role)) {
			return (
				<>
					<AdminLogoutButton onClick={onClickLogout}>로그아웃</AdminLogoutButton>
					<AdminPageButton onClick={onClickAdminPage}>운영자 페이지</AdminPageButton>
				</>
			);
		}
		return <LogoutButton onClick={onClickLogout}>로그아웃</LogoutButton>;
	};

	const renderMobileLoginButton = () => {
		if (!user) {
			return (
				<MenuItemLogin onClick={onClickLogin}>
					<span>로그인</span>
					<MobileLoginImage src={MobileLogoutImg} />
				</MenuItemLogin>
			);
		}

		return (
			<MenuItemLogin onClick={onClickLogout}>
				<span>로그아웃</span>
				<MobileLoginImage src={MobileLogoutImg} />
			</MenuItemLogin>
		);
	};

	return (
		<Container>
			<Desktop>
				<TopLine />
			</Desktop>
			<TopContainer>
				<Desktop>
					<TopComponent>
						<LogoImage onClick={onClickLogo} src={LogoImg} />
						<LogoText onClick={onClickLogo}>{CustomResource.site_title}</LogoText>
					</TopComponent>
					<TopComponent>
						<MenuContainer onClick={() => setShowMobileMenu(false)}>
							{menus.map((menu, idx) => {
								return (
									<MenuItemContainer key={idx}>
										<MenuItem
											selected={location.pathname.includes(menu.path)}
											onClick={() => navigate(menu.link)}
										>
											{menu.title}
										</MenuItem>
									</MenuItemContainer>
								);
							})}
						</MenuContainer>
						{renderLoginButton()}
					</TopComponent>
				</Desktop>
				<Mobile>
					<MobileMenuImage src={MobileMenuImg} onClick={onClickMobileMenu} />
					<LogoImage onClick={onClickLogo} src={LogoMobileImg} />
					<LogoText onClick={onClickLogo}>{CustomResource.site_title}</LogoText>
					{user && <MobileCalendarImage src={MobileCalendarImg} onClick={() => navigate('/booking')} />}
					{showMobileMenu && (
						<MenuContainer onClick={() => setShowMobileMenu(false)}>
							<MenuContent>
								{renderMobileLoginButton()}
								{menus.map((menu, idx) => {
									return (
										<MenuItemContainer key={idx}>
											<MenuItem
												selected={location.pathname.includes(menu.path)}
												onClick={() => navigate(menu.link)}
											>
												{menu.title}
												<MenuItemArrow>{'>'}</MenuItemArrow>
											</MenuItem>
										</MenuItemContainer>
									);
								})}
							</MenuContent>
						</MenuContainer>
					)}
				</Mobile>
			</TopContainer>
		</Container>
	);
};

const Container = styled.div`
	position: fixed;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100vw;
	height: 90px;
	z-index: 100;
	background-color: white;
	box-sizing: border-box;
	border-bottom: 1px solid #dddddd;

	@media only screen and (max-width: 767.98px) {
		height: 65px;
	}
`;

const TopLine = styled.div`
	width: 100%;
	height: 5px;
	background-color: #22499d;
`;

const TopContainer = styled.div`
	display: flex;
	position: relative;
	width: 100%;
	max-width: 1240px;
	height: 85px;
	align-items: center;
	justify-content: space-between;

	@media only screen and (max-width: 767.98px) {
		height: 65px;
		justify-content: center;
	}
`;

const TopComponent = styled.div`
	display: flex;
	height: 100%;
	flex-shrink: 0;
	align-items: center;
`;

const LogoImage = styled.img`
	width: 159px;
	height: 35px;
	cursor: pointer;

	@media only screen and (max-width: 767.98px) {
		width: 98px;
		height: auto;
	}
`;

const LogoText = styled.span`
	color: #000000;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 22px;
	line-height: 26px;
	margin-left: 20px;
	align-self: center;
	cursor: pointer;

	@media only screen and (max-width: 767.98px) {
		font-size: 14px;
		line-height: 17px;
		margin-left: 5px;
	}
`;

const MobileMenuImage = styled.img`
	position: absolute;
	left: 10px;
	top: 21px;
	width: 24px;
	height: 24px;
`;

const MobileCalendarImage = styled.img`
	position: absolute;
	right: 10px;
	top: 21px;
	width: 24px;
	height: 24px;
`;

const HeaderButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 7px 13px;
	box-sizing: border-box;
	height: 35px;
	cursor: pointer;

	border-radius: 17px;
	border: 1px solid #275da6;
	background-color: #ffffff;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #000000;
`;

const LoginButton = styled(HeaderButton)`
	margin-left: 53px;
`;

const LogoutButton = styled(HeaderButton)`
	margin-left: 53px;
`;

const AdminLogoutButton = styled(HeaderButton)`
	margin-left: 53px;
`;

const AdminPageButton = styled(HeaderButton)`
	margin-left: 10px;
`;

const MenuContainer = styled.div`
	display: flex;
	height: 100%;
	justify-content: center;
	align-items: center;

	@media only screen and (max-width: 767.98px) {
		justify-content: flex-start;
		width: 100%;
		height: calc(100% - 60px);
		position: fixed;
		z-index: 101;
		top: 60px;
		background-color: rgba(0, 0, 0, 0.5);
	}
`;

const MenuContent = styled.div`
	@media only screen and (max-width: 767.98px) {
		flex-direction: column;
		justify-content: flex-start;
		width: 180px;
		height: 100%;
		background-color: #ffffff;
	}
`;

const MenuItemContainer = styled.div`
	display: flex;

	@media only screen and (min-width: 768px) {
		height: 100%;
		& + & {
			margin-left: 77px;
		}
	}

	@media only screen and (max-width: 767.98px) {
		width: 100%;
	}
`;

const MenuItem = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
	border-bottom: 2px solid #ffffff;
	cursor: pointer;
	${(props) =>
		props.selected &&
		css`
			color: #0e498f;
			border-bottom: 3px solid #22499d;
			margin-bottom: -1px;
		`}

	@media only screen and (max-width: 767.98px) {
		justify-content: space-between;
		padding-left: 15px;
		width: 100%;
		height: 55px;
		border-bottom: 1px solid #dddddd;
		color: #333333;
		font-family: 'Pretendard';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
	}
`;

const MenuItemArrow = styled.div`
	padding-right: 19px;
	color: #333333;
	font-family: 'Pretendard';
	font-size: 14px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.42px;
	line-height: 100%;
`;

const MenuItemLogin = styled.div`
	width: 100%;
	height: 55px;
	padding-left: 15px;
	padding-right: 18px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;

	background-color: #22499d;
	color: #ffffff;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
`;

const MobileLoginImage = styled.img`
	width: 18px;
	height: 18px;
`;

export default Header;
