import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import useSizeDetector from '../hooks/useSizeDetector';

import LogoImg from '../custom/images/logo.png';
import CustomResource from '../custom/resource';

const Footer = () => {
	const navigate = useNavigate();
	const { Desktop, Mobile } = useSizeDetector();

	const onClickLogo = () => {
		navigate('/');
	};

	const onScrollTop = () => {
		window.scrollTo(0, 0);
	};

	return (
		<Container>
			<Content>
				<Desktop>
					<LogoContainer>
						<LogoImage onClick={onClickLogo} src={LogoImg} />
						<LogoText>{CustomResource.site_title}</LogoText>
						<Description>
							<DescriptionText>{CustomResource.address1}</DescriptionText>
							<DescriptionText>{CustomResource.address2}</DescriptionText>
							<DescriptionText>{CustomResource.contact}</DescriptionText>
							<CopyrightText>{CustomResource.copyright}</CopyrightText>
						</Description>
					</LogoContainer>
					<ScrollTopButton onClick={onScrollTop}>↑</ScrollTopButton>
				</Desktop>
				<Mobile>
					<Description>
						<DescriptionText>{CustomResource.address1}</DescriptionText>
						<DescriptionText>{CustomResource.address2}</DescriptionText>
						<DescriptionText>{CustomResource.contact}</DescriptionText>
						<CopyrightText>{CustomResource.copyright}</CopyrightText>
					</Description>
				</Mobile>
			</Content>
		</Container>
	);
};

const Container = styled.div`
	width: 100%;
	height: 180px;
	border-top: 1px solid #dddddd;
	display: flex;
	justify-content: center;
	align-items: center;

	@media only screen and (max-width: 767.98px) {
		height: 127px;
	}
`;

const Content = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	max-width: 1240px;

	@media only screen and (max-width: 767.98px) {
		justify-content: center;
	}
`;

const LogoContainer = styled.div`
	display: flex;
	align-items: center;
	height: 90px;
`;

const LogoImage = styled.img`
	width: 159px;
	height: 35px;
	cursor: pointer;
`;

const LogoText = styled.span`
	margin-left: 20px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 22px;
	line-height: 26px;
`;

const Description = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 92px;

	@media only screen and (max-width: 767.98px) {
		margin-left: 0;
	}
`;

const DescriptionText = styled.span`
	display: block;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #999999;

	@media only screen and (max-width: 767.98px) {
		text-align: center;
		font-size: 13px;
		line-height: 16px;
	}
`;

const CopyrightText = styled.span`
	margin-top: 21px;
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #333333;

	@media only screen and (max-width: 767.98px) {
		margin-top: 16px;
		text-align: center;
		font-size: 11px;
		line-height: 13px;
	}
`;

const ScrollTopButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 60px;
	height: 60px;

	cursor: pointer;
	border: 1px solid #dddddd;

	font-size: 14px;
	font-weight: 700;
`;

export default Footer;
