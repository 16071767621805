import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { UserContext } from '../contexts/UserContext';

const Login = () => {
	const navigate = useNavigate();
	const { user } = useContext(UserContext);

	useEffect(() => {
		if (user) {
			loginCompleted();
		}
	}, [user]);

	const onClickSSOLogin = () => {
		window.location =
			'https://www.rec.ac.kr/xn-sso/gw.php?login_type=standalone&site=studio&callback_url=https%3A%2F%2Fstudio.rec.ac.kr%2Flogin%2Fcallback';
	};

	const loginCompleted = () => {
		navigate('/');
	};

	return (
		<Container>
			<Title>로그인</Title>
			<LoginWrapper>
				<SsoLoginButtonContainer>
					<Button type="sso" onClick={onClickSSOLogin}>
						통합로그인
					</Button>
				</SsoLoginButtonContainer>
			</LoginWrapper>
		</Container>
	);
};

const Container = styled.div`
	width: 380px;
	margin-top: 50px;
	margin-bottom: 87px;
	padding: 60px 40px 30px 40px;
	background-color: #ffffff;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	@media only screen and (max-width: 767.98px) {
		width: calc(100% - 20px);
		margin: 10px 10px 50px 10px;
		height: auto;
		padding: 30px 20px 21px 20px;
	}
`;

const Title = styled.span`
	margin-bottom: 30px;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 36px;
	line-height: 43px;
	color: #000000;

	@media only screen and (max-width: 767.98px) {
		margin-bottom: 10px;
		font-size: 32px;
		line-height: 38px;
	}
`;

const LoginWrapper = styled.div`
	width: 100%;
	padding-top: 30px;
	padding-bottom: 30px;

	display: flex;
	flex-direction: column;
	align-items: center;

	&:last-child {
		border-top: 1px solid #cccccc;
	}
`;

const LoginTitle = styled.div`
	margin-bottom: 20px;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 29px;
	color: #333333;
`;

const Button = styled.div`
	width: 100%;
	height: 50px;

	background-color: #22499d;
	border-radius: 5px;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 22px;

	display: flex;
	align-items: center;
	justify-content: center;
	color: #ffffff;
	cursor: pointer;

	&:hover {
		background-color: #0c438d;
	}

	${(props) =>
		props.type === 'sso' &&
		css`
			background-color: #00b1a6;

			&:hover {
				background-color: #008f87;
			}
		`}

	@media only screen and (max-width: 767.98px) {
		margin-top: 0;
	}
`;

const SsoLoginButtonContainer = styled.div`
	width: 100%;

	display: flex;
	flex-direction: column;
	align-items: center;

	@media only screen and (max-width: 767.98px) {
		margin-top: 0px;
	}
`;

export default Login;
